import { FC } from 'react'

import { Button } from '@/components/button'
import { Box } from '@/components/layout'
import { AlertProps } from '@/types/dialog'

import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogRoot,
  AlertDialogTitle,
} from './styles'

export const AlertDialog: FC<AlertProps> = ({
  title,
  open,
  setOpen,
  description,
  handleOnConfirm,
  submitText = 'OK',
  cancelText = 'Cancel',
  hideCancel = false,
  isDestructive = false,
}) => (
  <AlertDialogRoot open={open} onOpenChange={setOpen}>
    <AlertDialogPortal>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogTitle>{title}</AlertDialogTitle>
        <AlertDialogDescription>{description}</AlertDialogDescription>
        <Box css={{ width: '100%' }}>
          {!hideCancel && (
            <AlertDialogCancel asChild>
              <Button
                css={{
                  flex: 1,
                  margin: '$4',
                }}
                mode={handleOnConfirm ? 'outline' : 'filled'}
              >
                {cancelText}
              </Button>
            </AlertDialogCancel>
          )}
          {handleOnConfirm && (
            <AlertDialogAction asChild>
              <Button
                fullWidth={hideCancel}
                onClick={handleOnConfirm}
                color={isDestructive ? 'danger' : 'primary'}
                css={{ flex: 1, margin: '$4' }}
              >
                {submitText}
              </Button>
            </AlertDialogAction>
          )}
        </Box>
      </AlertDialogContent>
    </AlertDialogPortal>
  </AlertDialogRoot>
)
