import { infiniteQueryOptions, useQuery } from '@tanstack/react-query'

import { getDisbursementDetails, getDisbursements } from '@/api/disbursements'

export const GET_DISBURSEMENTS_QUERY = 'get-disbursements-query'

export const disbursementsQueryOptions = infiniteQueryOptions({
  queryKey: [GET_DISBURSEMENTS_QUERY],
  queryFn: async ({ pageParam }) => {
    const { data } = await getDisbursements({
      size: 10,
      status: 'COMPLETED',
      page: pageParam,
      sort: 'time_created',
      direction: 'desc',
    })
    return data
  },
  initialPageParam: 1,
  getNextPageParam: lastPage =>
    lastPage?.has_next_page ? lastPage?.page_number + 1 : undefined,
})

export const useGetDisbursementDetails = (detailsRef: string, id: number) => {
  return useQuery({
    queryKey: [GET_DISBURSEMENTS_QUERY, id],

    queryFn: async () => {
      const { data } = await getDisbursementDetails(detailsRef)
      return data
    },

    enabled: false,
  })
}
