import { queryOptions } from '@tanstack/react-query'

import { getOrgInfo } from '@/api/organization'

export const ORGANIZATION_INFO_QUERY = 'organization-info-query'

export const orgInfoQueryOptions = (orgId: string) =>
  queryOptions({
    queryKey: [ORGANIZATION_INFO_QUERY, orgId],
    queryFn: async () => {
      if (!orgId) return null
      const { data } = await getOrgInfo(orgId)
      return data
    },
    select: data => {
      const orgData = data?.data[0]
      const orgName = orgData?.name
      return { orgId, orgName, orgInfo: orgData }
    },
    enabled: !!orgId,
  })
