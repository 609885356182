import { styled } from '@/styles/stitches.config'

export const UL = styled('ul', {
  listStyle: 'none',
  padding: 0,

  '& > li': {
    mb: '$16',
  },
})
