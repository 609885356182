import { OrganizationInfoResponse } from '@/types/organization'

import { branchHttp } from './branch-http'

/**
 * Retrieves organization information.
 * uses seperate axios instance since endpoint is not protected by auth
 * @param uuid organization uuid
 */
export const getOrgInfo = (uuid: string) =>
  branchHttp.get<OrganizationInfoResponse>(`/p1/organizations?uuid=${uuid}`)
