import { useMemo } from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'

import { useAuth } from '@/context/Auth'
import { payoutFeesQueryOptions } from '@/queries/payout'
import { PayoutFeeType } from '@/types/payout'

export const usePayoutFee = () => {
  const { orgId } = useAuth()

  const payoutFee = useSuspenseQuery(payoutFeesQueryOptions(orgId))

  const instantFee = useMemo(
    () =>
      payoutFee.data?.find(
        fee => fee.payout_fee_type === PayoutFeeType.INSTANT
      ),
    [payoutFee.data]
  )

  const formattedFee = useMemo(() => {
    if (!instantFee) return ''
    if (instantFee.base_fee === 0 && instantFee.fee_percent > 0)
      return `${instantFee.fee_percent}% of funds`
    if (instantFee.fee_percent === 0 && instantFee.base_fee > 0)
      return `$${(instantFee.base_fee / 100).toFixed(2)} minimum`
    if (instantFee.base_fee > 0 && instantFee.fee_percent > 0)
      return `${instantFee.fee_percent}% of funds ($${(
        instantFee.base_fee / 100
      ).toFixed(2)} minimum)`
    return ''
  }, [instantFee])

  return {
    formattedFee,
    instantFee,
  }
}
