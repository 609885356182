export enum SupportedPaymentTypes {
  WALLET = 'WALLET',
  DIRECT_CARD = 'DIRECT_CARD',
  DIRECT_BANK = 'DIRECT_BANK_ACCOUNT',
}
export interface OrganizationInfo {
  id: number
  org_key: string
  name: string
  partner_id: number
  logo_url: string
  auto_join_default_location: boolean
  branding_color_hex_code: string
  default_location_id: string
  onboarding_redirect_deeplink: string
  require_roster_match_before_kyc: boolean
  app_identifier: string
  supported_payment_types: SupportedPaymentTypes
}

export interface OrganizationInfoResponse {
  data: OrganizationInfo[]
}
