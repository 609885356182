import { FC } from 'react'

import { Badge } from '@/components/badge'
import { BranchDirectCardStatus, WalletStatus } from '@/types/profile'

interface Props {
  status: string | WalletStatus
}

export const AccountStatusBadge: FC<Props> = ({ status }) => {
  switch (status) {
    case BranchDirectCardStatus.COMPLETE:
      return <Badge type="success">Connected</Badge>
    case BranchDirectCardStatus.INCOMPLETE:
      return <Badge type="danger">Incomplete</Badge>
    default:
      return null
  }
}
