import { FC } from 'react'
import { Cross2Icon } from '@radix-ui/react-icons'

import { ToastProps } from '@/types/toast'

import { Box } from '../layout'
import {
  ToastClose,
  ToastContainer,
  ToastDescription,
  ToastIcon,
  ToastRoot,
  ToastTitle,
  ToastViewport,
} from './styles'

export const Toast: FC<ToastProps> = ({
  open,
  setOpen,
  icon,
  title,
  description,
}) => (
  <ToastContainer>
    <ToastRoot open={open} onOpenChange={setOpen} data-swipe-direction="right">
      <Box>
        <ToastIcon>{icon}</ToastIcon>
        {!!title && <ToastTitle>{title}</ToastTitle>}
        <ToastClose>
          <Cross2Icon />
        </ToastClose>
      </Box>
      <ToastDescription>{!!description && description}</ToastDescription>
    </ToastRoot>
    <ToastViewport />
  </ToastContainer>
)
