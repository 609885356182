import { createContext, FC, ReactNode, useState } from 'react'
import { Provider } from '@radix-ui/react-toast'

import { SVGIcon } from '@/components/svgIcon'
import { Toast } from '@/components/toast'
import { ShowToastProps, ToastContextType } from '@/types/toast'

export const ToastContext = createContext<ToastContextType>({
  showToast: () => null,
  hideToast: () => null,
})

const initialToastProps: ShowToastProps = {
  title: '',
  description: '',
  icon: <SVGIcon iconName="check_circled" />,
}

interface Props {
  children: ReactNode
}

export const ToastProvider: FC<Props> = ({ children }) => {
  const [toastOpen, setToastOpen] = useState(false)
  const [toastProp, setToastProps] = useState<ShowToastProps>(initialToastProps)

  const showToast = (props: ShowToastProps) => {
    setToastProps(props)
    setToastOpen(true)
  }

  const hideToast = () => {
    setToastProps(initialToastProps)
    setToastOpen(false)
  }

  const toastContextValues = {
    showToast,
    hideToast,
  }

  return (
    <Provider>
      <ToastContext.Provider value={toastContextValues}>
        <Toast {...toastProp} setOpen={setToastOpen} open={toastOpen} />
        {children}
      </ToastContext.Provider>
    </Provider>
  )
}
