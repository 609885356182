import { createContext, FC, ReactNode, useState } from 'react'

import { AlertDialog, Dialog } from '@/components/dialog'
import {
  DialogContextType,
  ShowAlertProps,
  ShowDialogProps,
} from '@/types/dialog'

export const DialogContext = createContext<DialogContextType>({
  showAlert: () => null,
  hideAlert: () => null,
  showDialog: () => null,
  hideDialog: () => null,
})

const initialAlertProps: ShowAlertProps = {
  title: '',
  description: '',
  submitText: '',
  cancelText: '',
  handleOnConfirm: () => null,
}

const initialDialogProps: ShowDialogProps = {
  title: '',
  description: '',
  children: null,
}

interface Props {
  children: ReactNode
}

export const DialogProvider: FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [alertProps, setAlertProps] =
    useState<ShowAlertProps>(initialAlertProps)
  const [dialogProps, setDialogProps] =
    useState<ShowDialogProps>(initialDialogProps)

  const showAlert = (props: ShowAlertProps) => {
    hideDialog()
    setAlertProps(props)
    setOpen(true)
  }

  const hideAlert = () => {
    setAlertProps(initialAlertProps)
    setOpen(false)
  }

  const showDialog = (props: ShowDialogProps) => {
    hideAlert()
    setDialogProps(props)
    setDialogOpen(true)
  }

  const hideDialog = () => {
    setDialogProps(initialDialogProps)
    setDialogOpen(false)
  }

  const dialogContextValues = {
    showAlert,
    hideAlert,
    showDialog,
    hideDialog,
  }

  return (
    <DialogContext.Provider value={dialogContextValues}>
      <AlertDialog {...alertProps} setOpen={hideAlert} open={open} />
      <Dialog {...dialogProps} open={dialogOpen} setOpen={hideDialog}>
        {dialogProps.children}
      </Dialog>
      {children}
    </DialogContext.Provider>
  )
}
