import { Box } from '@/components/layout'
import { styled } from '@/styles/stitches.config'

export const PayoutHistoryContainer = styled(Box, {
  flexDirection: 'column',
  mb: '$16',
  '& > div:nth-child(even)': {
    backgroundColor: '$gray400',
  },
})
