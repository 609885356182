import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'

import { styled } from '@/styles/stitches.config'

const SCROLLBAR_SIZE = 10

export const ScrollAreaContainer = styled(ScrollAreaPrimitive.Root, {
  borderRadius: '$sm',
  overflow: 'hidden',
  height: '100%',
  width: '100%',
})

export const ScrollAreaViewport = styled(ScrollAreaPrimitive.Viewport, {
  width: '100%',
  height: '100%',
  br: 'inherit',
})

export const ScrollAreaScrollbar = styled(ScrollAreaPrimitive.Scrollbar, {
  display: 'flex',
  // ensures no selection
  userSelect: 'none',
  // disable browser handling of all panning and zooming gestures on touch devices
  touchAction: 'none',
  padding: 2,

  transition: 'background 160ms ease-out',
  '&:hover': { background: '$secondary700' },
  '&[data-orientation="vertical"]': { width: SCROLLBAR_SIZE },
  '&[data-orientation="horizontal"]': {
    flexDirection: 'column',
    height: SCROLLBAR_SIZE,
  },
})

export const ScrollThumb = styled(ScrollAreaPrimitive.Thumb, {
  flex: 1,
  background: '$gray600',
  borderRadius: SCROLLBAR_SIZE,
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    minWidth: 44,
    minHeight: 44,
  },
})

export const ScrollAreaCorner = styled(ScrollAreaPrimitive.Corner, {
  background: '$gray400',
})
