import { Box, Flex } from '@/components/layout'
import { styled } from '@/styles/stitches.config'

export const PageLayoutContainer = styled(Flex, {
  width: '80vw',
  margin: 'auto',

  '@tablet': {
    width: '95vw',
    margin: '0 auto',
  },
})

export const PageLayoutHeader = styled(Flex, {
  padding: '$16 0',
  width: '100%',
})

export const PageLoadingContainer = styled(Flex, {
  height: '100dvh',
  defaultVariants: {
    justify: 'center',
    align: 'center',
  },
})

export const OrgLogoContainer = styled(Box, {
  img: {
    maxWidth: '120px',
    maxHeight: '80px',
  },
  svg: {
    width: '120px',
    height: '100%',
  },
})

export const PageLayoutFooter = styled(Flex, {
  width: '100%',
  height: '50px',
  marginTop: 'auto',
})

export const PageLayoutContent = styled(Flex, {
  width: '100%',
  margin: '64px 0',
})

export const PageLayoutContentChildren = styled(Flex, {
  width: '100%',
  maxWidth: '100%',
  height: '100%',
})

export const PageLayoutContentContainer = styled(Flex, {
  width: '100%',
  maxWidth: '100%',
  overflowY: 'auto',
})

export const PageLayoutItem = styled(Flex, {
  width: '100%',
  height: '100%',

  variants: {
    hideOnSmallerScreen: {
      true: {
        '@tablet': {
          display: 'none',
        },
      },
    },
  },
})

export const PartnerLogoContainer = styled(Box, {
  width: '100%',
  height: '100%',
  maxWidth: '400px',
  br: '$xxl',

  img: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },

  defaultVariants: {
    justify: 'center',
    align: 'center',
  },
})
