import { FC } from 'react'
import { Box } from '@branch-messenger/willow-ui'

import { Description, Title } from '@/components/typography'
import { PayoutHistory } from '@/containers/PayoutHistory'
import { useOrg } from '@/selectors/useOrg'

import { AccountProfile } from './AccountProfile'

export const AccountOverview: FC = () => {
  const { orgName } = useOrg()

  return (
    <Box>
      <Title>Manage your {orgName} payments.</Title>
      <Description size="sm" css={{ mt: '$16', mb: '$18' }}>
        If you are having any issues, please contact Branch support{' '}
        <a
          target="_blank"
          href="https://support.branchapp.com/hc/en-us/requests/new"
        >
          here
        </a>
      </Description>
      <AccountProfile />
      <PayoutHistory />
    </Box>
  )
}
