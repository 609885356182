import * as DropdownPrimitive from '@radix-ui/react-dropdown-menu'

import { styled } from '@/styles/stitches.config'

export const DropdownContentContainer = styled(DropdownPrimitive.Content, {
  backgroundColor: 'white',
  borderRadius: '$md',
  padding: '$8',
  boxShadow: '$2',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
  },
  zIndex: 1000,
  maxWidth: '100%',
  '@mobile': {
    width: '100vw',
    padding: 0,
  },
})

export const DropdownArrow = styled(DropdownPrimitive.Arrow, {
  fill: 'white',
})

export const DropdownMenuItem = styled(DropdownPrimitive.DropdownMenuItem, {
  color: '$secondary400',
  fontWeight: '$md',
  padding: '$8',
  margin: '$2 $8',
  fontSize: '$16',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '$gray500',
  },

  '&:focus': {
    outline: 'none',
  },
})

export const DropdownMenu = styled(DropdownPrimitive.Root, {
  '&:focus': {
    outline: 'none',
  },
})

export const DropdownMenuTrigger = styled(DropdownPrimitive.Trigger, {})

export const DropdownPortal = styled(DropdownPrimitive.Portal, {
  width: '100%',
})
