export const colors = {
  primary400: '#b8ffd3',
  primary500: '#2abd61',

  secondary500: '#1F6ADB',
  secondary300: '#D1E3FD',

  success500: '#2abd61',
  success300: '#D1FDE2',

  danger500: '#F53D46',
  danger300: '#FDD1D1',

  warn500: '#F58A3D',
  warn300: '#FDE3D1',

  gray300: '#78769C',
  gray400: '#F8F6F4',
  gray500: '#E1E1E1',
  gray600: '#CDCDCD',
  gray700: '#616161',

  dark500: '#2b303a',
}
