import { FC, useState } from 'react'

import {
  useUpdateAccountEmail,
  useUpdateAccountEmailOTP,
} from '@/queries/registration'

import { AccountUpdateCodeVerification } from './AccountUpdateCodeVerification'
import { AccountUpdateEmailSent } from './AccountUpdateEmailSent'
import { EmailUpdateForm } from './EmailUpdateForm'

export const EmailUpdate: FC = () => {
  const [step, setStep] = useState(0)
  const [email, setEmail] = useState('')
  const updateAccountEmailOTP = useUpdateAccountEmailOTP()
  const updateAccountEmail = useUpdateAccountEmail()

  const handleEmailUpdateSubmit = () => {
    updateAccountEmailOTP.mutate(undefined, {
      onSuccess: () => setStep(1),
    })
  }

  const resendCode = () => {
    updateAccountEmailOTP.mutate()
  }

  const confirmCode = (code: string) => {
    updateAccountEmail.mutate(
      {
        email,
        otp: code,
      },
      {
        onSuccess: () => setStep(2),
      }
    )
  }

  switch (step) {
    case 0:
      return (
        <EmailUpdateForm
          isPending={updateAccountEmailOTP.isPending}
          email={email}
          setEmail={setEmail}
          onSubmit={handleEmailUpdateSubmit}
        />
      )
    case 1:
      return (
        <AccountUpdateCodeVerification
          resendCode={resendCode}
          confirmCode={confirmCode}
          verifyCodePending={updateAccountEmail.isPending}
          requestCodePending={updateAccountEmailOTP.isPending}
        />
      )
    case 2:
      return <AccountUpdateEmailSent email={email} />
    default:
      return (
        <EmailUpdateForm
          isPending={updateAccountEmailOTP.isPending}
          email={email}
          setEmail={setEmail}
          onSubmit={handleEmailUpdateSubmit}
        />
      )
  }
}
