import { FC, useRef } from 'react'

import { Button } from '@/components/button'
import { Box, Flex } from '@/components/layout'
import { SVGIcon } from '@/components/svgIcon'
import { Description, Title, Typography } from '@/components/typography'
import { useTimer } from '@/hooks/useTimer'
import { useRequestResetPassword } from '@/queries/reset-password'
import { useOrg } from '@/selectors/useOrg'

import { RequestPasswordResetProps } from './RequestPasswordReset'

export const LinkSent: FC<RequestPasswordResetProps> = ({ email }) => {
  const counterRef = useRef<HTMLSpanElement>(null)
  const { isCompleted, resetCountDown } = useTimer(0, 60, counterRef)
  const requestResetPassword = useRequestResetPassword()

  const { orgName } = useOrg()
  const resendEmail = () => {
    resetCountDown()
    requestResetPassword.mutate({ email: email })
  }

  return (
    <Box direction="column">
      <Flex justify="center" css={{ mb: '$24' }}>
        <SVGIcon iconName="green_check" />
      </Flex>
      <Box direction="column" align="start">
        <Title>We’ve emailed you a link to reset your password.</Title>
        <Description>
          Check your email. If your {orgName} account is linked to an email
          address, you will receive an email containing a link to reset your
          password.
        </Description>
        <Description bold>Do not share this link with anyone.</Description>
      </Box>
      <Box direction="column" align="start" css={{ mt: '$24' }}>
        <Typography size="sm" color="muted">
          Didn't get an email?
        </Typography>
        <Typography
          bold
          color="muted"
          size="sm"
          css={{
            display: `${isCompleted ? 'none' : 'block'}`,
            mt: '$4',
            mb: ' $8',
          }}
        >
          Resend Email in <span ref={counterRef}></span>
        </Typography>
        {isCompleted && (
          <Button mode="link" onClick={resendEmail}>
            Resend Link {`>`}
          </Button>
        )}
      </Box>
    </Box>
  )
}
