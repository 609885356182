import { styled } from '@/styles/stitches.config'

export const CreateDebitCardContainer = styled('div', {
  position: 'relative',
  width: '100%',

  '& iframe': {
    width: '100%',
    height: '65dvh',
    border: 'none',
  },

  '@mobile': {
    '& iframe': {
      height: '75dvh',
    },
  },
})
