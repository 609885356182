import { FC } from 'react'

import { Box } from '@/components/layout'
import { useVerifyUser } from '@/queries/registration'

import { PasscodeInput } from './PasscodeInput'
import { SSNInput } from './SSNInput'

interface Props {
  track_id: string
  org_passcode_required: boolean
}

export interface PasscodeFormValues {
  passcode: string
}

export const EnterCode: FC<Props> = ({ track_id, org_passcode_required }) => {
  const verifyUser = useVerifyUser()

  const handlePinSubmit = (pin: string[]): void =>
    verifyUser.mutate({
      password: pin.join(''),
      track_id,
      org_passcode_required,
    })

  const handlePasscodeSubmit = (values: PasscodeFormValues): void => {
    verifyUser.mutate({
      password: values.passcode,
      track_id,
      org_passcode_required,
    })
  }

  return (
    <Box direction="column" align="start">
      {org_passcode_required ? (
        <PasscodeInput handlePasscodeSubmit={handlePasscodeSubmit} />
      ) : (
        <SSNInput
          handlePinSubmit={handlePinSubmit}
          isPending={verifyUser.isPending}
        />
      )}
    </Box>
  )
}
