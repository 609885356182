import { FC, useContext } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { Button } from '@/components/button'
import { Input } from '@/components/input'
import { Box } from '@/components/layout'
import { Description, Span, Title, Typography } from '@/components/typography'
import { DialogContext } from '@/context'

import { PasscodeFormValues } from './EnterCode'
import { GetHelpContent } from './GetHelpContent'

const schema = yup.object().shape({
  passcode: yup.string().required('Passcode is required'),
})

interface Props {
  handlePasscodeSubmit: (values: PasscodeFormValues) => void
}

export const PasscodeInput: FC<Props> = ({ handlePasscodeSubmit }) => {
  const { showDialog } = useContext(DialogContext)
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<PasscodeFormValues>({
    defaultValues: {
      passcode: '',
    },
    resolver: yupResolver(schema),
  })

  const openGetHelpDialog = () => {
    showDialog({
      title: 'Contact Support',
      description: 'How would you like to be helped?',
      children: <GetHelpContent />,
    })
  }

  return (
    <>
      <Title>Confirm your workplace.</Title>
      <Description css={{ mb: '$24' }}>
        Please enter the <Span bold>Workplace Passcode</Span> provided by your
        company.
      </Description>
      <form
        onSubmit={handleSubmit(handlePasscodeSubmit)}
        style={{ width: '100%' }}
      >
        <Box direction="column" align="start">
          <Typography color="hint" size="sm">
            Workplace passcode
          </Typography>
          <Input {...register('passcode')} type="text" placeholder="Passcode" />
          <Typography color="alert" size="sm">
            {errors.passcode?.message}
          </Typography>
        </Box>
        <Box
          direction="column"
          justify="between"
          align="start"
          css={{ mt: '$24' }}
        >
          <Typography size="sm" color="muted">
            Problem with passcode?
          </Typography>
          <Button
            type="button"
            mode="link"
            css={{ mt: '$4' }}
            onClick={openGetHelpDialog}
          >
            Get Help {`>`}
          </Button>
        </Box>

        <Button type="submit" disabled={!isValid} css={{ mt: '$8' }}>
          {!isValid ? 'Enter Passcode' : 'Continue'}
        </Button>
      </form>
    </>
  )
}
