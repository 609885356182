import axios, { AxiosError } from 'axios'
import * as Sentry from '@sentry/react'

import { IResponse } from '@/types/common'

export type ExceptionOptionProps<T> = {
  onHttpError: (err: AxiosError<T>) => void
  onOtherError?: (err: unknown) => void
}

/** Pass an exception error and use the callbacks to handle different error types */
export const handleHttpException = <T = IResponse>(
  error: unknown,
  options: ExceptionOptionProps<T>
) => {
  if (axios.isAxiosError(error)) {
    options.onHttpError(error)
  } else {
    Sentry.captureException(error)
    options.onOtherError && options.onOtherError(error)
  }
}
