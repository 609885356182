import Cookies from 'js-cookie'

const tokenCookie = 'Authorization'

export const removeTokenCookie = (): void => Cookies.remove(tokenCookie)

const employeeIdCookieKey = 'employeeId'

export const setEmployeeIdCookie = (userId: string): string => {
  Cookies.set(employeeIdCookieKey, userId, {
    secure: process.env.NODE_ENV === 'production',
    sameSite: 'strict',
  })
  return userId
}

const jwtDecode = (t: string) => ({
  raw: t,
  header: JSON.parse(window.atob(t.split('.')[0])),
  payload: JSON.parse(window.atob(t.split('.')[1])),
})

export const getEmployeeIdCookie = (): string | null =>
  Cookies.get(employeeIdCookieKey) || null

export const removeEmployeeIdCookie = (): void =>
  Cookies.remove(employeeIdCookieKey)

export const parseToken = (token?: string) => (token ? jwtDecode(token) : null)

export const getEmployeeIdFromTokenString = (token: string) => {
  const parsed = parseToken(token)
  return parsed ? parsed.payload.sub : null
}

export const cleanUpCookieandStorage = () => {
  removeEmployeeIdCookie()
  removeTokenCookie()
}
