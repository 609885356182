import { ChangeEvent, FC, useState } from 'react'

import { SVGIcon } from '@/components/svgIcon'

import { IconInput } from './IconInput'

interface Props {
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
}

export const PasswordInput: FC<Props> = ({ onChange, value, onBlur }) => {
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  return (
    <IconInput
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      placeholder="Password"
      type={showPassword ? 'text' : 'password'}
      iconPlacement="right"
      iconClick={toggleShowPassword}
      inputIcon={
        showPassword ? (
          <SVGIcon iconName="eye_slash" wrapperStyle={{ display: 'flex' }} />
        ) : (
          <SVGIcon iconName="eye" wrapperStyle={{ display: 'flex' }} />
        )
      }
    />
  )
}
