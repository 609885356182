import { styled } from '@/styles/stitches.config'

export const StatusIndicator = styled('div', {
  position: 'relative',
  width: '48px',
  ml: '$4',
})

export const ErrorIndicator = styled('div', {
  position: 'absolute',
  bottom: 0,
  right: 0,
  transform: 'translate(-120%, 30%)',
  display: 'flex',
})
