import { FC, InputHTMLAttributes, ReactNode } from 'react'

import { Input, InputIcon, InputWrapper } from '.'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  iconClick: () => void
  inputIcon: ReactNode
  iconPlacement: 'left' | 'right'
}

export const IconInput: FC<Props> = ({
  inputIcon,
  iconPlacement,
  iconClick,
  ...rest
}) => (
  <InputWrapper>
    {iconPlacement === 'left' && (
      <InputIcon css={{ cursor: 'pointer' }} onClick={iconClick}>
        {inputIcon}
      </InputIcon>
    )}
    <Input {...rest} noBorder />
    {iconPlacement === 'right' && (
      <InputIcon css={{ cursor: 'pointer' }} onClick={iconClick}>
        {inputIcon}
      </InputIcon>
    )}
  </InputWrapper>
)
