import { FC } from 'react'
import { Box, Button, Typography } from '@branch-messenger/willow-ui'

import { PhoneNumberInput } from '@/components/phone-input'
import { Title } from '@/components/typography'

interface Props {
  phone: string
  isPending: boolean
  setPhone: (phone: string) => void
  onSubmit: () => void
}

export const PhoneUpdateForm: FC<Props> = ({
  phone,
  isPending,
  onSubmit,
  setPhone,
}) => {
  const handleInputChange = (value: string) => {
    if (!value) return setPhone('')
    setPhone(value)
  }

  return (
    <Box $direction="column" $align="start">
      <Title css={{ mb: '$40' }}>What's your new phone number?</Title>
      <PhoneNumberInput initialPhoneNumber={''} onChange={handleInputChange} />
      <Typography $size="sm" $color="textMuted" style={{ marginTop: '8px' }}>
        Standard messaging rates may apply.
      </Typography>
      <Box $direction="column" $align="start" $mt={10}>
        <Button
          style={{ margin: '$16 0 $40 0', marginRight: 'auto' }}
          disabled={!phone || isPending}
          onClick={() => onSubmit()}
        >
          {!phone ? 'Enter Info' : 'Continue'}
        </Button>
      </Box>
    </Box>
  )
}
