import { FC, useEffect } from 'react'

import { OptionsMenu } from '@/components/options'
import { sendAnalyticsEvent } from '@/lib/mixpanel'

const ContactSupportOptions = [
  {
    iconName: 'chat',
    title: 'Chat',
  },
  {
    iconName: 'call',
    title: 'Phone',
  },
  {
    iconName: 'mail',
    title: 'Email',
  },
  {
    iconName: 'help',
    title: 'FAQs',
  },
]

export const ContactSupportContent: FC = () => {
  useEffect(
    () => sendAnalyticsEvent('ONBOARDING', 'identity-support:get-help'),
    []
  )

  return <OptionsMenu options={ContactSupportOptions} />
}
