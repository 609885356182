import { createFileRoute } from '@tanstack/react-router'

import { DebitCard } from '@/containers/DebitCard'
import { payoutFeesQueryOptions } from '@/queries/payout'

export const Route = createFileRoute('/debit-card')({
  component: DebitCard,
  loader: ({ context }) =>
    context.queryClient.ensureQueryData(
      payoutFeesQueryOptions(context.auth.orgId)
    ),
})
