import { useContext } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'

import {
  getWorker,
  login,
  loginWorker,
  logout,
  sendEmailVerification,
  verifyLogin,
} from '@/api/auth'
import { DialogContext } from '@/context'
import { useAuth } from '@/context/Auth'
import { handleHttpException } from '@/lib/httpException'
import {
  clearMixpanelUser,
  identifyUser,
  sendAnalyticsEvent,
  setUserProperties,
} from '@/lib/mixpanel'
import { cleanUpCookieandStorage } from '@/lib/token'
import { LoginRequestBody } from '@/types/auth'

import { ORGANIZATION_INFO_QUERY } from './organization'

export const useSendEmailVerificationMutation = () => {
  return useMutation({
    mutationFn: async (email: string) => await sendEmailVerification(email),
  })
}

export const useLogin = (handleEmailVerificationError?: () => void) => {
  const navigate = useNavigate()
  const { showAlert } = useContext(DialogContext)
  const handleLoginError = () => {
    showAlert({
      title: 'Unable To Login',
      description: 'Please check your information and try again.',
      submitText: 'OK',
      handleOnConfirm: () => null,
      hideCancel: true,
    })
  }
  return useMutation({
    mutationFn: async (data: LoginRequestBody) => {
      const res = await login(data)
      return res.data
    },

    onSuccess: () => sendAnalyticsEvent('AUTH', 'login:success'),

    onError: (error, payload) => {
      sendAnalyticsEvent('AUTH', 'login:failure')
      handleHttpException(error, {
        onHttpError: error => {
          if (error.response?.status === 412) {
            if (handleEmailVerificationError) {
              return handleEmailVerificationError()
            }
            navigate({
              to: '/setup/email-verification/$email',
              params: { email: payload.email },
              state: { loginRequestBody: payload },
            })
          } else {
            handleLoginError()
          }
        },
        onOtherError: handleLoginError,
      })
    },
  })
}

export const useVerifyCode = () => {
  const { showAlert } = useContext(DialogContext)

  return useMutation({
    mutationFn: async (data: LoginRequestBody) => {
      const res = await verifyLogin(data)
      return res.data
    },

    onSuccess: (_, payload) => {
      setUserProperties({ email: payload?.email })
    },

    onError: () => {
      showAlert({
        title: 'Unable To Login',
        description: 'Please check your information and try again.',
        submitText: 'OK',
        handleOnConfirm: () => null,
        hideCancel: true,
      })
    },
  })
}

export const useLogout = () => {
  const queryClient = useQueryClient()
  const { showAlert } = useContext(DialogContext)
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async () => {
      const res = await logout()
      clearMixpanelUser()
      return res.data
    },

    onSuccess: () => {
      cleanUpCookieandStorage()
      queryClient.invalidateQueries({ queryKey: [ORGANIZATION_INFO_QUERY] })
      navigate({ to: '/login' })
    },

    onError: () => {
      showAlert({
        title: 'Unable To Logout',
        description: 'Please try again.',
        submitText: 'OK',
        handleOnConfirm: () => null,
        hideCancel: true,
      })
    },
  })
}

export const useLoginUserMutation = () => {
  const { setOrgId } = useAuth()
  const navigate = useNavigate()
  const { showAlert } = useContext(DialogContext)
  return useMutation({
    mutationFn: async () => {
      const workerInfo = await getWorker()
      const { employee_id, org_id } = workerInfo.data[0]
      identifyUser(`${org_id}-${employee_id}`)
      await loginWorker(workerInfo.data[0])
      return workerInfo
    },
    onSuccess: ({ data }) => {
      const orgId = data[0].org_uuid as string
      setOrgId(orgId)
      navigate({ to: '/account' })
    },
    onError: () => {
      showAlert({
        title: 'Unable To Login',
        description: 'Please check your information and try again.',
        submitText: 'OK',
        handleOnConfirm: () => {
          cleanUpCookieandStorage()
          navigate({ to: '/login' })
        },
        hideCancel: true,
      })
    },
  })
}
