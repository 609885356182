import { FC } from 'react'

import { Box } from '@/components/layout'
import { ProgressBar } from '@/components/progress'
import { Typography } from '@/components/typography'

interface Props {
  pwStrength: number
  label: string
  color: string
}

export const PasswordStrengthIndicator: FC<Props> = ({
  pwStrength,
  label,
  color,
}) => (
  <Box direction="column">
    <Box justify="between" css={{ mb: '$4' }}>
      <Typography>Strength:</Typography>
      <Typography
        css={{
          color: color,
        }}
      >
        {label}
      </Typography>
    </Box>
    <ProgressBar progress={pwStrength} indicatorColor={color} />
  </Box>
)
