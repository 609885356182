import { Country } from '@/types/countries'

export const COUNTRIES: Country[] = [
  {
    country: 'US',
    emoji: '🇺🇸',
    name: 'United States',
    phone: '1',
  },
  {
    country: 'AD',
    emoji: '🇦🇩',
    name: 'Andorra',
    phone: '376',
  },
  {
    country: 'AE',
    emoji: '🇦🇪',
    name: 'United Arab Emirates',
    phone: '971',
  },
  {
    country: 'AF',
    emoji: '🇦🇫',
    name: 'Afghanistan',
    phone: '93',
  },
  {
    country: 'AG',
    emoji: '🇦🇬',
    name: 'Antigua and Barbuda',
    phone: '1268',
  },
  {
    country: 'AI',
    emoji: '🇦🇮',
    name: 'Anguilla',
    phone: '1264',
  },
  {
    country: 'AL',
    emoji: '🇦🇱',
    name: 'Albania',
    phone: '355',
  },
  {
    country: 'AM',
    emoji: '🇦🇲',
    name: 'Armenia',
    phone: '374',
  },
  {
    country: 'AO',
    emoji: '🇦🇴',
    name: 'Angola',
    phone: '244',
  },
  {
    country: 'AR',
    emoji: '🇦🇷',
    name: 'Argentina',
    phone: '54',
  },
  {
    country: 'AS',
    emoji: '🇦🇸',
    name: 'American Samoa',
    phone: '1684',
  },
  {
    country: 'AT',
    emoji: '🇦🇹',
    name: 'Austria',
    phone: '43',
  },
  {
    country: 'AU',
    emoji: '🇦🇺',
    name: 'Australia',
    phone: '61',
  },
  {
    country: 'AW',
    emoji: '🇦🇼',
    name: 'Aruba',
    phone: '297',
  },
  {
    country: 'AX',
    emoji: '🇦🇽',
    name: 'Åland',
    phone: '358',
  },
  {
    country: 'AZ',
    emoji: '🇦🇿',
    name: 'Azerbaijan',
    phone: '994',
  },
  {
    country: 'BA',
    emoji: '🇧🇦',
    name: 'Bosnia and Herzegovina',
    phone: '387',
  },
  {
    country: 'BB',
    emoji: '🇧🇧',
    name: 'Barbados',
    phone: '1246',
  },
  {
    country: 'BD',
    emoji: '🇧🇩',
    name: 'Bangladesh',
    phone: '880',
  },
  {
    country: 'BE',
    emoji: '🇧🇪',
    name: 'Belgium',
    phone: '32',
  },
  {
    country: 'BF',
    emoji: '🇧🇫',
    name: 'Burkina Faso',
    phone: '226',
  },
  {
    country: 'BG',
    emoji: '🇧🇬',
    name: 'Bulgaria',
    phone: '359',
  },
  {
    country: 'BH',
    emoji: '🇧🇭',
    name: 'Bahrain',
    phone: '973',
  },
  {
    country: 'BI',
    emoji: '🇧🇮',
    name: 'Burundi',
    phone: '257',
  },
  {
    country: 'BJ',
    emoji: '🇧🇯',
    name: 'Benin',
    phone: '229',
  },
  {
    country: 'BL',
    emoji: '🇧🇱',
    name: 'Saint Barthélemy',
    phone: '590',
  },
  {
    country: 'BM',
    emoji: '🇧🇲',
    name: 'Bermuda',
    phone: '1441',
  },
  {
    country: 'BN',
    emoji: '🇧🇳',
    name: 'Brunei',
    phone: '673',
  },
  {
    country: 'BO',
    emoji: '🇧🇴',
    name: 'Bolivia',
    phone: '591',
  },
  {
    country: 'BQ',
    emoji: '🇧🇶',
    name: 'Bonaire',
    phone: '5997',
  },
  {
    country: 'BR',
    emoji: '🇧🇷',
    name: 'Brazil',
    phone: '55',
  },
  {
    country: 'BS',
    emoji: '🇧🇸',
    name: 'Bahamas',
    phone: '1242',
  },
  {
    country: 'BT',
    emoji: '🇧🇹',
    name: 'Bhutan',
    phone: '975',
  },
  {
    country: 'BW',
    emoji: '🇧🇼',
    name: 'Botswana',
    phone: '267',
  },
  {
    country: 'BY',
    emoji: '🇧🇾',
    name: 'Belarus',
    phone: '375',
  },
  {
    country: 'BZ',
    emoji: '🇧🇿',
    name: 'Belize',
    phone: '501',
  },
  {
    country: 'CA',
    emoji: '🇨🇦',
    name: 'Canada',
    phone: '1',
  },
  {
    country: 'CC',
    emoji: '🇨🇨',
    name: 'Cocos [Keeling] Islands',
    phone: '61',
  },
  {
    country: 'CD',
    emoji: '🇨🇩',
    name: 'Democratic Republic of the Congo',
    phone: '243',
  },
  {
    country: 'CF',
    emoji: '🇨🇫',
    name: 'Central African Republic',
    phone: '236',
  },
  {
    country: 'CG',
    emoji: '🇨🇬',
    name: 'Republic of the Congo',
    phone: '242',
  },
  {
    country: 'CH',
    emoji: '🇨🇭',
    name: 'Switzerland',
    phone: '41',
  },
  {
    country: 'CI',
    emoji: '🇨🇮',
    name: 'Ivory Coast',
    phone: '225',
  },
  {
    country: 'CK',
    emoji: '🇨🇰',
    name: 'Cook Islands',
    phone: '682',
  },
  {
    country: 'CL',
    emoji: '🇨🇱',
    name: 'Chile',
    phone: '56',
  },
  {
    country: 'CM',
    emoji: '🇨🇲',
    name: 'Cameroon',
    phone: '237',
  },
  {
    country: 'CN',
    emoji: '🇨🇳',
    name: 'China',
    phone: '86',
  },
  {
    country: 'CO',
    emoji: '🇨🇴',
    name: 'Colombia',
    phone: '57',
  },
  {
    country: 'CR',
    emoji: '🇨🇷',
    name: 'Costa Rica',
    phone: '506',
  },
  {
    country: 'CU',
    emoji: '🇨🇺',
    name: 'Cuba',
    phone: '53',
  },
  {
    country: 'CV',
    emoji: '🇨🇻',
    name: 'Cape Verde',
    phone: '238',
  },
  {
    country: 'CW',
    emoji: '🇨🇼',
    name: 'Curacao',
    phone: '5999',
  },
  {
    country: 'CX',
    emoji: '🇨🇽',
    name: 'Christmas Island',
    phone: '61',
  },
  {
    country: 'CY',
    emoji: '🇨🇾',
    name: 'Cyprus',
    phone: '357',
  },
  {
    country: 'CZ',
    emoji: '🇨🇿',
    name: 'Czech Republic',
    phone: '420',
  },
  {
    country: 'DE',
    emoji: '🇩🇪',
    name: 'Germany',
    phone: '49',
  },
  {
    country: 'DJ',
    emoji: '🇩🇯',
    name: 'Djibouti',
    phone: '253',
  },
  {
    country: 'DK',
    emoji: '🇩🇰',
    name: 'Denmark',
    phone: '45',
  },
  {
    country: 'DM',
    emoji: '🇩🇲',
    name: 'Dominica',
    phone: '1767',
  },
  {
    country: 'DO',
    emoji: '🇩🇴',
    name: 'Dominican Republic',
    phone: '1',
  },
  {
    country: 'DZ',
    emoji: '🇩🇿',
    name: 'Algeria',
    phone: '213',
  },
  {
    country: 'EC',
    emoji: '🇪🇨',
    name: 'Ecuador',
    phone: '593',
  },
  {
    country: 'EE',
    emoji: '🇪🇪',
    name: 'Estonia',
    phone: '372',
  },
  {
    country: 'EG',
    emoji: '🇪🇬',
    name: 'Egypt',
    phone: '20',
  },
  {
    country: 'EH',
    emoji: '🇪🇭',
    name: 'Western Sahara',
    phone: '212',
  },
  {
    country: 'ER',
    emoji: '🇪🇷',
    name: 'Eritrea',
    phone: '291',
  },
  {
    country: 'ES',
    emoji: '🇪🇸',
    name: 'Spain',
    phone: '34',
  },
  {
    country: 'ET',
    emoji: '🇪🇹',
    name: 'Ethiopia',
    phone: '251',
  },
  {
    country: 'FI',
    emoji: '🇫🇮',
    name: 'Finland',
    phone: '358',
  },
  {
    country: 'FJ',
    emoji: '🇫🇯',
    name: 'Fiji',
    phone: '679',
  },
  {
    country: 'FK',
    emoji: '🇫🇰',
    name: 'Falkland Islands',
    phone: '500',
  },
  {
    country: 'FM',
    emoji: '🇫🇲',
    name: 'Micronesia',
    phone: '691',
  },
  {
    country: 'FO',
    emoji: '🇫🇴',
    name: 'Faroe Islands',
    phone: '298',
  },
  {
    country: 'FR',
    emoji: '🇫🇷',
    name: 'France',
    phone: '33',
  },
  {
    country: 'GA',
    emoji: '🇬🇦',
    name: 'Gabon',
    phone: '241',
  },
  {
    country: 'GB',
    emoji: '🇬🇧',
    name: 'United Kingdom',
    phone: '44',
  },
  {
    country: 'GD',
    emoji: '🇬🇩',
    name: 'Grenada',
    phone: '1473',
  },
  {
    country: 'GE',
    emoji: '🇬🇪',
    name: 'Georgia',
    phone: '995',
  },
  {
    country: 'GF',
    emoji: '🇬🇫',
    name: 'French Guiana',
    phone: '594',
  },
  {
    country: 'GG',
    emoji: '🇬🇬',
    name: 'Guernsey',
    phone: '44',
  },
  {
    country: 'GH',
    emoji: '🇬🇭',
    name: 'Ghana',
    phone: '233',
  },
  {
    country: 'GI',
    emoji: '🇬🇮',
    name: 'Gibraltar',
    phone: '350',
  },
  {
    country: 'GL',
    emoji: '🇬🇱',
    name: 'Greenland',
    phone: '299',
  },
  {
    country: 'GM',
    emoji: '🇬🇲',
    name: 'Gambia',
    phone: '220',
  },
  {
    country: 'GN',
    emoji: '🇬🇳',
    name: 'Guinea',
    phone: '224',
  },
  {
    country: 'GP',
    emoji: '🇬🇵',
    name: 'Guadeloupe',
    phone: '590',
  },
  {
    country: 'GQ',
    emoji: '🇬🇶',
    name: 'Equatorial Guinea',
    phone: '240',
  },
  {
    country: 'GR',
    emoji: '🇬🇷',
    name: 'Greece',
    phone: '30',
  },
  {
    country: 'GT',
    emoji: '🇬🇹',
    name: 'Guatemala',
    phone: '502',
  },
  {
    country: 'GU',
    emoji: '🇬🇺',
    name: 'Guam',
    phone: '1671',
  },
  {
    country: 'GW',
    emoji: '🇬🇼',
    name: 'Guinea-Bissau',
    phone: '245',
  },
  {
    country: 'GY',
    emoji: '🇬🇾',
    name: 'Guyana',
    phone: '592',
  },
  {
    country: 'HK',
    emoji: '🇭🇰',
    name: 'Hong Kong',
    phone: '852',
  },
  {
    country: 'HN',
    emoji: '🇭🇳',
    name: 'Honduras',
    phone: '504',
  },
  {
    country: 'HR',
    emoji: '🇭🇷',
    name: 'Croatia',
    phone: '385',
  },
  {
    country: 'HT',
    emoji: '🇭🇹',
    name: 'Haiti',
    phone: '509',
  },
  {
    country: 'HU',
    emoji: '🇭🇺',
    name: 'Hungary',
    phone: '36',
  },
  {
    country: 'ID',
    emoji: '🇮🇩',
    name: 'Indonesia',
    phone: '62',
  },
  {
    country: 'IE',
    emoji: '🇮🇪',
    name: 'Ireland',
    phone: '353',
  },
  {
    country: 'IL',
    emoji: '🇮🇱',
    name: 'Israel',
    phone: '972',
  },
  {
    country: 'IM',
    emoji: '🇮🇲',
    name: 'Isle of Man',
    phone: '44',
  },
  {
    country: 'IN',
    emoji: '🇮🇳',
    name: 'India',
    phone: '91',
  },
  {
    country: 'IO',
    emoji: '🇮🇴',
    name: 'British Indian Ocean Territory',
    phone: '246',
  },
  {
    country: 'IQ',
    emoji: '🇮🇶',
    name: 'Iraq',
    phone: '964',
  },
  {
    country: 'IR',
    emoji: '🇮🇷',
    name: 'Iran',
    phone: '98',
  },
  {
    country: 'IS',
    emoji: '🇮🇸',
    name: 'Iceland',
    phone: '354',
  },
  {
    country: 'IT',
    emoji: '🇮🇹',
    name: 'Italy',
    phone: '39',
  },
  {
    country: 'JE',
    emoji: '🇯🇪',
    name: 'Jersey',
    phone: '44',
  },
  {
    country: 'JM',
    emoji: '🇯🇲',
    name: 'Jamaica',
    phone: '1876',
  },
  {
    country: 'JO',
    emoji: '🇯🇴',
    name: 'Jordan',
    phone: '962',
  },
  {
    country: 'JP',
    emoji: '🇯🇵',
    name: 'Japan',
    phone: '81',
  },
  {
    country: 'KE',
    emoji: '🇰🇪',
    name: 'Kenya',
    phone: '254',
  },
  {
    country: 'KG',
    emoji: '🇰🇬',
    name: 'Kyrgyzstan',
    phone: '996',
  },
  {
    country: 'KH',
    emoji: '🇰🇭',
    name: 'Cambodia',
    phone: '855',
  },
  {
    country: 'KI',
    emoji: '🇰🇮',
    name: 'Kiribati',
    phone: '686',
  },
  {
    country: 'KM',
    emoji: '🇰🇲',
    name: 'Comoros',
    phone: '269',
  },
  {
    country: 'KN',
    emoji: '🇰🇳',
    name: 'Saint Kitts and Nevis',
    phone: '1869',
  },
  {
    country: 'KP',
    emoji: '🇰🇵',
    name: 'North Korea',
    phone: '850',
  },
  {
    country: 'KR',
    emoji: '🇰🇷',
    name: 'South Korea',
    phone: '82',
  },
  {
    country: 'KW',
    emoji: '🇰🇼',
    name: 'Kuwait',
    phone: '965',
  },
  {
    country: 'KY',
    emoji: '🇰🇾',
    name: 'Cayman Islands',
    phone: '1345',
  },
  {
    country: 'KZ',
    emoji: '🇰🇿',
    name: 'Kazakhstan',
    phone: '76,77',
  },
  {
    country: 'LA',
    emoji: '🇱🇦',
    name: 'Laos',
    phone: '856',
  },
  {
    country: 'LB',
    emoji: '🇱🇧',
    name: 'Lebanon',
    phone: '961',
  },
  {
    country: 'LC',
    emoji: '🇱🇨',
    name: 'Saint Lucia',
    phone: '1758',
  },
  {
    country: 'LI',
    emoji: '🇱🇮',
    name: 'Liechtenstein',
    phone: '423',
  },
  {
    country: 'LK',
    emoji: '🇱🇰',
    name: 'Sri Lanka',
    phone: '94',
  },
  {
    country: 'LR',
    emoji: '🇱🇷',
    name: 'Liberia',
    phone: '231',
  },
  {
    country: 'LS',
    emoji: '🇱🇸',
    name: 'Lesotho',
    phone: '266',
  },
  {
    country: 'LT',
    emoji: '🇱🇹',
    name: 'Lithuania',
    phone: '370',
  },
  {
    country: 'LU',
    emoji: '🇱🇺',
    name: 'Luxembourg',
    phone: '352',
  },
  {
    country: 'LV',
    emoji: '🇱🇻',
    name: 'Latvia',
    phone: '371',
  },
  {
    country: 'LY',
    emoji: '🇱🇾',
    name: 'Libya',
    phone: '218',
  },
  {
    country: 'MA',
    emoji: '🇲🇦',
    name: 'Morocco',
    phone: '212',
  },
  {
    country: 'MC',
    emoji: '🇲🇨',
    name: 'Monaco',
    phone: '377',
  },
  {
    country: 'MD',
    emoji: '🇲🇩',
    name: 'Moldova',
    phone: '373',
  },
  {
    country: 'ME',
    emoji: '🇲🇪',
    name: 'Montenegro',
    phone: '382',
  },
  {
    country: 'MF',
    emoji: '🇲🇫',
    name: 'Saint Martin',
    phone: '590',
  },
  {
    country: 'MG',
    emoji: '🇲🇬',
    name: 'Madagascar',
    phone: '261',
  },
  {
    country: 'MH',
    emoji: '🇲🇭',
    name: 'Marshall Islands',
    phone: '692',
  },
  {
    country: 'MK',
    emoji: '🇲🇰',
    name: 'North Macedonia',
    phone: '389',
  },
  {
    country: 'ML',
    emoji: '🇲🇱',
    name: 'Mali',
    phone: '223',
  },
  {
    country: 'MM',
    emoji: '🇲🇲',
    name: 'Myanmar [Burma]',
    phone: '95',
  },
  {
    country: 'MN',
    emoji: '🇲🇳',
    name: 'Mongolia',
    phone: '976',
  },
  {
    country: 'MO',
    emoji: '🇲🇴',
    name: 'Macao',
    phone: '853',
  },
  {
    country: 'MP',
    emoji: '🇲🇵',
    name: 'Northern Mariana Islands',
    phone: '1670',
  },
  {
    country: 'MQ',
    emoji: '🇲🇶',
    name: 'Martinique',
    phone: '596',
  },
  {
    country: 'MR',
    emoji: '🇲🇷',
    name: 'Mauritania',
    phone: '222',
  },
  {
    country: 'MS',
    emoji: '🇲🇸',
    name: 'Montserrat',
    phone: '1664',
  },
  {
    country: 'MT',
    emoji: '🇲🇹',
    name: 'Malta',
    phone: '356',
  },
  {
    country: 'MU',
    emoji: '🇲🇺',
    name: 'Mauritius',
    phone: '230',
  },
  {
    country: 'MV',
    emoji: '🇲🇻',
    name: 'Maldives',
    phone: '960',
  },
  {
    country: 'MW',
    emoji: '🇲🇼',
    name: 'Malawi',
    phone: '265',
  },
  {
    country: 'MX',
    emoji: '🇲🇽',
    name: 'Mexico',
    phone: '52',
  },
  {
    country: 'MY',
    emoji: '🇲🇾',
    name: 'Malaysia',
    phone: '60',
  },
  {
    country: 'MZ',
    emoji: '🇲🇿',
    name: 'Mozambique',
    phone: '258',
  },
  {
    country: 'NA',
    emoji: '🇳🇦',
    name: 'Namibia',
    phone: '264',
  },
  {
    country: 'NC',
    emoji: '🇳🇨',
    name: 'New Caledonia',
    phone: '687',
  },
  {
    country: 'NE',
    emoji: '🇳🇪',
    name: 'Niger',
    phone: '227',
  },
  {
    country: 'NF',
    emoji: '🇳🇫',
    name: 'Norfolk Island',
    phone: '672',
  },
  {
    country: 'NG',
    emoji: '🇳🇬',
    name: 'Nigeria',
    phone: '234',
  },
  {
    country: 'NI',
    emoji: '🇳🇮',
    name: 'Nicaragua',
    phone: '505',
  },
  {
    country: 'NL',
    emoji: '🇳🇱',
    name: 'Netherlands',
    phone: '31',
  },
  {
    country: 'NO',
    emoji: '🇳🇴',
    name: 'Norway',
    phone: '47',
  },
  {
    country: 'NP',
    emoji: '🇳🇵',
    name: 'Nepal',
    phone: '977',
  },
  {
    country: 'NR',
    emoji: '🇳🇷',
    name: 'Nauru',
    phone: '674',
  },
  {
    country: 'NU',
    emoji: '🇳🇺',
    name: 'Niue',
    phone: '683',
  },
  {
    country: 'NZ',
    emoji: '🇳🇿',
    name: 'New Zealand',
    phone: '64',
  },
  {
    country: 'OM',
    emoji: '🇴🇲',
    name: 'Oman',
    phone: '968',
  },
  {
    country: 'PA',
    emoji: '🇵🇦',
    name: 'Panama',
    phone: '507',
  },
  {
    country: 'PE',
    emoji: '🇵🇪',
    name: 'Peru',
    phone: '51',
  },
  {
    country: 'PF',
    emoji: '🇵🇫',
    name: 'French Polynesia',
    phone: '689',
  },
  {
    country: 'PG',
    emoji: '🇵🇬',
    name: 'Papua New Guinea',
    phone: '675',
  },
  {
    country: 'PH',
    emoji: '🇵🇭',
    name: 'Philippines',
    phone: '63',
  },
  {
    country: 'PK',
    emoji: '🇵🇰',
    name: 'Pakistan',
    phone: '92',
  },
  {
    country: 'PL',
    emoji: '🇵🇱',
    name: 'Poland',
    phone: '48',
  },
  {
    country: 'PM',
    emoji: '🇵🇲',
    name: 'Saint Pierre and Miquelon',
    phone: '508',
  },
  {
    country: 'PR',
    emoji: '🇵🇷',
    name: 'Puerto Rico',
    phone: '1',
  },
  {
    country: 'PS',
    emoji: '🇵🇸',
    name: 'Palestine',
    phone: '970',
  },
  {
    country: 'PT',
    emoji: '🇵🇹',
    name: 'Portugal',
    phone: '351',
  },
  {
    country: 'PW',
    emoji: '🇵🇼',
    name: 'Palau',
    phone: '680',
  },
  {
    country: 'PY',
    emoji: '🇵🇾',
    name: 'Paraguay',
    phone: '595',
  },
  {
    country: 'QA',
    emoji: '🇶🇦',
    name: 'Qatar',
    phone: '974',
  },
  {
    country: 'RE',
    emoji: '🇷🇪',
    name: 'Réunion',
    phone: '262',
  },
  {
    country: 'RO',
    emoji: '🇷🇴',
    name: 'Romania',
    phone: '40',
  },
  {
    country: 'RS',
    emoji: '🇷🇸',
    name: 'Serbia',
    phone: '381',
  },
  {
    country: 'RU',
    emoji: '🇷🇺',
    name: 'Russia',
    phone: '7',
  },
  {
    country: 'RW',
    emoji: '🇷🇼',
    name: 'Rwanda',
    phone: '250',
  },
  {
    country: 'SA',
    emoji: '🇸🇦',
    name: 'Saudi Arabia',
    phone: '966',
  },
  {
    country: 'SB',
    emoji: '🇸🇧',
    name: 'Solomon Islands',
    phone: '677',
  },
  {
    country: 'SC',
    emoji: '🇸🇨',
    name: 'Seychelles',
    phone: '248',
  },
  {
    country: 'SD',
    emoji: '🇸🇩',
    name: 'Sudan',
    phone: '249',
  },
  {
    country: 'SE',
    emoji: '🇸🇪',
    name: 'Sweden',
    phone: '46',
  },
  {
    country: 'SG',
    emoji: '🇸🇬',
    name: 'Singapore',
    phone: '65',
  },
  {
    country: 'SH',
    emoji: '🇸🇭',
    name: 'Saint Helena',
    phone: '290',
  },
  {
    country: 'SI',
    emoji: '🇸🇮',
    name: 'Slovenia',
    phone: '386',
  },
  {
    country: 'SJ',
    emoji: '🇸🇯',
    name: 'Svalbard and Jan Mayen',
    phone: '4779',
  },
  {
    country: 'SK',
    emoji: '🇸🇰',
    name: 'Slovakia',
    phone: '421',
  },
  {
    country: 'SL',
    emoji: '🇸🇱',
    name: 'Sierra Leone',
    phone: '232',
  },
  {
    country: 'SM',
    emoji: '🇸🇲',
    name: 'San Marino',
    phone: '378',
  },
  {
    country: 'SN',
    emoji: '🇸🇳',
    name: 'Senegal',
    phone: '221',
  },
  {
    country: 'SO',
    emoji: '🇸🇴',
    name: 'Somalia',
    phone: '252',
  },
  {
    country: 'SR',
    emoji: '🇸🇷',
    name: 'Suriname',
    phone: '597',
  },
  {
    country: 'SS',
    emoji: '🇸🇸',
    name: 'South Sudan',
    phone: '211',
  },
  {
    country: 'ST',
    emoji: '🇸🇹',
    name: 'São Tomé and Príncipe',
    phone: '239',
  },
  {
    country: 'SV',
    emoji: '🇸🇻',
    name: 'El Salvador',
    phone: '503',
  },
  {
    country: 'SX',
    emoji: '🇸🇽',
    name: 'Sint Maarten',
    phone: '1721',
  },
  {
    country: 'SY',
    emoji: '🇸🇾',
    name: 'Syria',
    phone: '963',
  },
  {
    country: 'SZ',
    emoji: '🇸🇿',
    name: 'Swaziland',
    phone: '268',
  },
  {
    country: 'TC',
    emoji: '🇹🇨',
    name: 'Turks and Caicos Islands',
    phone: '1649',
  },
  {
    country: 'TD',
    emoji: '🇹🇩',
    name: 'Chad',
    phone: '235',
  },
  {
    country: 'TG',
    emoji: '🇹🇬',
    name: 'Togo',
    phone: '228',
  },
  {
    country: 'TH',
    emoji: '🇹🇭',
    name: 'Thailand',
    phone: '66',
  },
  {
    country: 'TJ',
    emoji: '🇹🇯',
    name: 'Tajikistan',
    phone: '992',
  },
  {
    country: 'TK',
    emoji: '🇹🇰',
    name: 'Tokelau',
    phone: '690',
  },
  {
    country: 'TL',
    emoji: '🇹🇱',
    name: 'East Timor',
    phone: '670',
  },
  {
    country: 'TM',
    emoji: '🇹🇲',
    name: 'Turkmenistan',
    phone: '993',
  },
  {
    country: 'TN',
    emoji: '🇹🇳',
    name: 'Tunisia',
    phone: '216',
  },
  {
    country: 'TO',
    emoji: '🇹🇴',
    name: 'Tonga',
    phone: '676',
  },
  {
    country: 'TR',
    emoji: '🇹🇷',
    name: 'Turkey',
    phone: '90',
  },
  {
    country: 'TT',
    emoji: '🇹🇹',
    name: 'Trinidad and Tobago',
    phone: '1868',
  },
  {
    country: 'TV',
    emoji: '🇹🇻',
    name: 'Tuvalu',
    phone: '688',
  },
  {
    country: 'TW',
    emoji: '🇹🇼',
    name: 'Taiwan',
    phone: '886',
  },
  {
    country: 'TZ',
    emoji: '🇹🇿',
    name: 'Tanzania',
    phone: '255',
  },
  {
    country: 'UA',
    emoji: '🇺🇦',
    name: 'Ukraine',
    phone: '380',
  },
  {
    country: 'UG',
    emoji: '🇺🇬',
    name: 'Uganda',
    phone: '256',
  },
  {
    country: 'UY',
    emoji: '🇺🇾',
    name: 'Uruguay',
    phone: '598',
  },
  {
    country: 'UZ',
    emoji: '🇺🇿',
    name: 'Uzbekistan',
    phone: '998',
  },
  {
    country: 'VA',
    emoji: '🇻🇦',
    name: 'Vatican City',
    phone: '379',
  },
  {
    country: 'VC',
    emoji: '🇻🇨',
    name: 'Saint Vincent and the Grenadines',
    phone: '1784',
  },
  {
    country: 'VE',
    emoji: '🇻🇪',
    name: 'Venezuela',
    phone: '58',
  },
  {
    country: 'VG',
    emoji: '🇻🇬',
    name: 'British Virgin Islands',
    phone: '1284',
  },
  {
    country: 'VI',
    emoji: '🇻🇮',
    name: 'U.S. Virgin Islands',
    phone: '1340',
  },
  {
    country: 'VN',
    emoji: '🇻🇳',
    name: 'Vietnam',
    phone: '84',
  },
  {
    country: 'VU',
    emoji: '🇻🇺',
    name: 'Vanuatu',
    phone: '678',
  },
  {
    country: 'WF',
    emoji: '🇼🇫',
    name: 'Wallis and Futuna',
    phone: '681',
  },
  {
    country: 'WS',
    emoji: '🇼🇸',
    name: 'Samoa',
    phone: '685',
  },
  {
    country: 'XK',
    emoji: '🇽🇰',
    name: 'Kosovo',
    phone: '383',
  },
  {
    country: 'YE',
    emoji: '🇾🇪',
    name: 'Yemen',
    phone: '967',
  },
  {
    country: 'YT',
    emoji: '🇾🇹',
    name: 'Mayotte',
    phone: '262',
  },
  {
    country: 'ZA',
    emoji: '🇿🇦',
    name: 'South Africa',
    phone: '27',
  },
  {
    country: 'ZM',
    emoji: '🇿🇲',
    name: 'Zambia',
    phone: '260',
  },
  {
    country: 'ZW',
    emoji: '🇿🇼',
    name: 'Zimbabwe',
    phone: '263',
  },
]
