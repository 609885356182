import { Typography } from '../typography'

export const VerifyCodeError = () => (
  <Typography size="lg">
    There was an error verifying your code. Please try again or contact Branch
    support{' '}
    <a
      target="_blank"
      href="https://support.branchapp.com/hc/en-us/requests/new"
    >
      here
    </a>
  </Typography>
)
