export interface CreateDebitCardInfo {
  token: string
  owner: {
    first_name: string
    last_name: string
    address: {
      address_1: string
      city: string
      state: string
      postal_code: string
    }
  }
}

export interface CreateDebitCardResponse {
  id: number
  network: string
  last_four: number
  expiration: string
  time_created: string
}

export enum VerifyCardErrorReasonCode {
  UNSUPPORTED_CARD_TYPE = 'UNSUPPORTED_CARD_TYPE',
  NOT_DEBIT_CARD = 'NOT_DEBIT_CARD',
  INVALID_CARD_DETAILS = 'INVALID_CARD_DETAILS',
}
