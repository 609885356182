import * as ToastPrimitive from '@radix-ui/react-toast'

import { keyframes, styled } from '@/styles/stitches.config'

import { Box } from '../layout'

const VIEWPORT_PADDING = 10

const hide = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
})

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: 'translateX(0)' },
})

export const ToastRoot = styled(ToastPrimitive.Root, {
  backgroundColor: 'white',
  borderRadius: '$md',
  boxShadow: '$popover',
  padding: '$16',
  display: 'flex',
  flexDirection: 'column',

  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${hide} 100ms ease-in`,
    },
  },
})

export const ToastContainer = styled(Box, {})

export const ToastIcon = styled(Box, {
  width: 'auto',
  marginRight: '$12',
  paddingTop: '$4',
})

export const ToastTitle = styled(ToastPrimitive.Title, {
  fontWeight: 'bold',
})

export const ToastDescription = styled(ToastPrimitive.Description, {
  overflowWrap: 'break-word',
})

export const ToastAction = styled(ToastPrimitive.Action, {})

export const ToastClose = styled(ToastPrimitive.Close, {
  ml: 'auto',
  cursor: 'pointer',
  opacity: 1,
  border: 'none',
  color: 'black',
  backgroundColor: 'white',
  width: 20,
})

export const ToastViewport = styled(ToastPrimitive.Viewport, {
  position: 'fixed',
  top: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  padding: VIEWPORT_PADDING,
  gap: 10,
  width: 400,
  maxWidth: '100vw',
  margin: 0,
  listStyle: 'none',
  outline: 'none',
  zIndex: 1000,
})
