import { createFileRoute } from '@tanstack/react-router'

import { VerifyCodeError } from '@/components/error-communications'
import { PageLoader } from '@/components/loader'
import { ResetPassword } from '@/containers/ResetPassword'
import { resetPasswordTrackIDQueryOptions } from '@/queries/reset-password'

type Search = {
  token: string
}

export const Route = createFileRoute(`/registration/password/exchange`)({
  validateSearch: (search: Record<string, unknown>): Search => {
    return {
      token: search.token as string,
    }
  },
  loaderDeps: ({ search }) => search,
  loader: ({ context: { queryClient }, deps: { token } }) =>
    queryClient.ensureQueryData(resetPasswordTrackIDQueryOptions(token)),
  component: ResetPassword,
  pendingComponent: PageLoader,
  errorComponent: VerifyCodeError,
})
