import { useState } from 'react'

import { DebitCardConnectedConfirmation } from './DebitCardConnectedConfirmation'
import { DebitCardForm } from './DebitCardForm'
import { DebitCardInformational } from './DebitCardInformational'

export const DebitCard = () => {
  const [step, setStep] = useState(0)
  switch (step) {
    case 0:
      return <DebitCardInformational setStep={setStep} />
    case 1:
      return <DebitCardForm setStep={setStep} />
    case 2:
      return <DebitCardConnectedConfirmation />
    default:
      return <DebitCardInformational setStep={setStep} />
  }
}
