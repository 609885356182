import { FC, useEffect } from 'react'

import { OptionsMenu } from '@/components/options'
import { sendAnalyticsEvent } from '@/lib/mixpanel'

const ContactSupportOptions = [
  {
    iconName: 'call',
    title: 'Phone',
  },
  {
    iconName: 'mail',
    title: 'Email',
  },
  {
    iconName: 'help',
    title: 'FAQs',
  },
]

export const GetHelpContent: FC = () => {
  useEffect(
    () => sendAnalyticsEvent('ONBOARDING', 'ssn-passcode-support:get-help'),
    []
  )

  return <OptionsMenu options={ContactSupportOptions} />
}
