import { FC, ReactElement, ReactNode } from 'react'

import {
  ScrollAreaContainer,
  ScrollAreaCorner,
  ScrollAreaScrollbar,
  ScrollAreaViewport,
  ScrollThumb,
} from './styles'

interface Props {
  children: ReactNode | ReactElement
}

export const ScrollArea: FC<Props> = ({ children }) => (
  <ScrollAreaContainer>
    <ScrollAreaViewport css={{ backgroundColor: 'white' }}>
      {children}
    </ScrollAreaViewport>
    <ScrollAreaScrollbar orientation="vertical">
      <ScrollThumb />
    </ScrollAreaScrollbar>
    <ScrollAreaScrollbar orientation="horizontal">
      <ScrollThumb />
    </ScrollAreaScrollbar>
    <ScrollAreaCorner />
  </ScrollAreaContainer>
)
