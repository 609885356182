import { useState } from 'react'

import { Box } from '@/components/layout'

import { EnterEmail } from './EnterEmail'
import { LinkSent } from './LinkSent'

export interface RequestPasswordResetProps {
  email: string
  setStep: (step: number) => void
  setEmail: (email: string) => void
}

const FormSteps = () => {
  const [step, setStep] = useState(0)
  const [email, setEmail] = useState('')
  const requestPasswordResetProps = { email, setStep, setEmail }
  switch (step) {
    case 0:
      return <EnterEmail {...requestPasswordResetProps} />
    case 1:
      return <LinkSent {...requestPasswordResetProps} />
    default:
      return <EnterEmail {...requestPasswordResetProps} />
  }
}

export const RequestPasswordReset = () => (
  <Box direction="column" align="start">
    <FormSteps />
  </Box>
)
