import { PageLayout } from '../page-layout'
import { Typography } from '../typography'

export const GenericError = () => (
  <PageLayout>
    <Typography size="sm" css={{ mt: '$16', mb: '$18' }}>
      An error occured. Please try again or contact Branch support{' '}
      <a
        target="_blank"
        href="https://support.branchapp.com/hc/en-us/requests/new"
      >
        here
      </a>
    </Typography>
  </PageLayout>
)
