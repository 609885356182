import { FC, useContext } from 'react'

import { Button } from '@/components/button'
import { Box } from '@/components/layout'
import { Description, Title, Typography } from '@/components/typography'
import { DialogContext } from '@/context'
import { InputType } from '@/types/registration'

import { GetHelpContent } from './GetHelpContent'

interface Props {
  phoneOrEmail: string
  inputType: InputType
  resetForm: () => void
}

export const LinkCreated: FC<Props> = ({
  resetForm,
  inputType,
  phoneOrEmail,
}) => {
  const { showDialog, hideDialog } = useContext(DialogContext)

  const openGetHelpDialog = () => {
    showDialog({
      title: 'Get Help',
      description:
        'Check with your organization to ensure that the correct email and phone number are on file for you.',
      children: (
        <GetHelpContent
          inputType={inputType}
          phoneOrEmail={phoneOrEmail}
          hideDialog={hideDialog}
          resetForm={resetForm}
        />
      ),
    })
  }

  return (
    <Box direction="column" align="start">
      <Title>
        Check your {inputType === InputType.EMAIL ? 'email' : 'phone'}.
      </Title>
      <Description size="sm" css={{ mt: '$16', mb: '$40' }}>
        <Box as="span">
          You should receive{' '}
          {inputType === InputType.EMAIL ? 'an email' : 'a phone'} message
          within a few minutes containing a secure link to connect a debit card
          and set up instant payouts to your existing bank account.
        </Box>
        <Box as="span" css={{ mt: '$16' }}>
          Please use the secure link sent to: {phoneOrEmail}
        </Box>
      </Description>
      <Typography color="muted">Didn’t get a link?</Typography>

      <Button mode="link" onClick={openGetHelpDialog}>
        Get Help {`>`}
      </Button>
    </Box>
  )
}
