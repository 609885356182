import {
  queryOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { getPaymentProfile, updatePaymentProfile } from '@/api/profile'
import { sendAnalyticsEvent } from '@/lib/mixpanel'
import { UpdatePaymentProfileRequest } from '@/types/profile'

export const GET_PAYMENT_PROFILE_QUERY = 'get-payment-profile-query'

export const paymentProfileQueryOptions = queryOptions({
  queryKey: [GET_PAYMENT_PROFILE_QUERY],
  queryFn: async () => {
    const { data } = await getPaymentProfile()
    return data
  },
})

export const useUpdatePaymentProfile = () => {
  const queryCache = useQueryClient()
  return useMutation({
    mutationFn: async (data: UpdatePaymentProfileRequest) => {
      const res = await updatePaymentProfile(data)
      return res.data
    },

    onSuccess: (_, data) => {
      sendAnalyticsEvent('ACCOUNT', 'payment-profile:update:success', {
        data,
      })
      queryCache.invalidateQueries({
        queryKey: [GET_PAYMENT_PROFILE_QUERY],
      })
    },

    onError: (_, data) =>
      sendAnalyticsEvent('ACCOUNT', 'payment-profile:update:failure', {
        data,
      }),
  })
}
