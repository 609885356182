import { FC, ReactElement, useEffect, useState } from 'react'

import { Box } from '@/components/layout'
import { Popover } from '@/components/popover'
import { Typography } from '@/components/typography'
import { usePasswordStrengthValidator } from '@/hooks/usePasswordStrengthValidator'

import { PasswordRequirements } from './PasswordRequirements'
import { PasswordStrengthIndicator } from './PasswordStrengthIndicator'

interface Props {
  password: string
  trigger: ReactElement
}

export const PasswordStrengthPopOver: FC<Props> = ({ trigger, password }) => {
  const [open, setOpen] = useState(false)

  const { passwordValidity, pwStrength, pWStrengthLabel, pWStrengthColor } =
    usePasswordStrengthValidator(password)

  useEffect(() => {
    if (password) setOpen(true)
  }, [password])

  return (
    <Popover trigger={trigger} open={open} setOpen={setOpen} disableAutoFocus>
      <Box
        align="start"
        direction="column"
        css={{
          br: '$md',
          maxWidth: '100vw',
          padding: 10,
          boxSizing: 'border-box',
        }}
        data-testid="popover"
      >
        <Typography bold>Your password must contain:</Typography>
        <PasswordRequirements passwordValidity={passwordValidity} />
        <PasswordStrengthIndicator
          pwStrength={pwStrength}
          label={pWStrengthLabel}
          color={pWStrengthColor}
        />
      </Box>
    </Popover>
  )
}
