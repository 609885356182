import { FC } from 'react'
import { CheckIcon } from '@radix-ui/react-icons'

import { Box } from '@/components/layout'

import {
  CheckboxIndicator,
  CheckboxRoot,
  CheckboxVariants,
  Label,
} from './styles'

interface Props {
  id: string
  label?: string
  mode?: CheckboxVariants['mode']
  defaultChecked?: boolean
  checked?: boolean
  disabled?: boolean
  onChanged?: (checked: boolean | 'indeterminate') => void
  size?: CheckboxVariants['size']
}

export const Checkbox: FC<Props> = ({
  id,
  defaultChecked = false,
  checked = false,
  label,
  disabled = false,
  mode,
  onChanged,
  size,
}) => (
  <Box align="center">
    <CheckboxRoot
      id={id}
      defaultChecked={defaultChecked}
      onCheckedChange={e => onChanged && onChanged(e)}
      checked={defaultChecked ? defaultChecked : checked}
      mode={mode}
      disabled={disabled}
      size={size}
    >
      <CheckboxIndicator>
        <CheckIcon />
      </CheckboxIndicator>
    </CheckboxRoot>
    {label && <Label>{label}</Label>}
  </Box>
)
