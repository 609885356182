/* 
  See: https://medium.com/@Florian/freebie-google-material-design-shadow-helper-2a0501295a2d
  Feel free to add more shadows here
 */
export const shadows = {
  1: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  2: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  3: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  4: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
  5: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
  popover:
    '0px 4px 3px 0px rgba(255, 255, 255, 0.12) inset, 0px 5px 16px 0px rgba(53, 52, 68, 0.16)',
}
