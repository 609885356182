import Mixpanel from 'mixpanel-browser'

type EventProperties = {
  [key: string]: string | number | boolean | null | number[] | object
}

type Domain =
  | 'PAGEVIEW'
  | 'ONBOARDING'
  | 'AUTH'
  | 'ACCOUNT'
  | 'RESET-PASSWORD'
  | 'UPDATE'

export const sendAnalyticsEvent = (
  domain: Domain,
  event: string,
  properties?: EventProperties
) => {
  const eventName = `${domain}|${event}`
  if (import.meta.env.VITE_MIXPANEL_ACTIVATED == 'true') {
    Mixpanel.track(eventName, properties)
  } else if (import.meta.env.VITE_MIXPANEL_DEBUGGING == 'true') {
    // eslint-disable-next-line no-console
    console.log('Tracking event: ', eventName, properties)
  }
}

export const identifyUser = (id: string) => {
  if (import.meta.env.VITE_MIXPANEL_ACTIVATED == 'true') {
    Mixpanel.identify(id)
  }
}

export const clearMixpanelUser = () => {
  if (import.meta.env.VITE_MIXPANEL_ACTIVATED == 'true') {
    Mixpanel.reset()
  }
}

type UserProperties = {
  email?: string
  user_id?: string
  phone_number?: string
}

export const setUserProperties = ({
  user_id,
  email,
  phone_number,
}: UserProperties) => {
  if (import.meta.env.VITE_MIXPANEL_ACTIVATED == 'true') {
    Mixpanel.people.set({
      $distinct_id: user_id,
      $email: email,
      $phone: phone_number && `+1${phone_number}`,
    })
  }
}
