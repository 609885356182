import React, { SVGProps, useEffect, useState } from 'react'

export const useDynamicSvgImport = (svgName: string) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<unknown>()
  const [SvgIcon, setSvgIcon] = useState<React.FC<SVGProps<SVGElement>>>()

  useEffect(() => {
    setLoading(true)
    setError(undefined)

    const importSvgIcon = async (): Promise<void> => {
      try {
        const { ReactComponent } = await import(
          `../assets/icons/${svgName}.svg`
        )
        setSvgIcon(() => ReactComponent)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    importSvgIcon()
  }, [svgName])

  return { loading, error, SvgIcon }
}
