import '@fontsource-variable/reddit-sans'

import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from 'styled-components'
import { theme } from '@branch-messenger/willow-ui'
import { QueryClientProvider } from '@tanstack/react-query'

import { DialogProvider } from '@/context/DialogContext'

import { App } from './App.tsx'
import { AuthProvider } from './context/Auth.tsx'
import { ToastProvider } from './context/ToastContext.tsx'
import { queryClient } from './lib/query-client.ts'
import { initializeAnalytics } from './lib/sentry.ts'

initializeAnalytics()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <DialogProvider>
              <App />
            </DialogProvider>
          </ToastProvider>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
)
