import { useSuspenseQuery } from '@tanstack/react-query'

import { Box } from '@/components/layout'
import { OptionsMenu } from '@/components/options'
import { Description } from '@/components/typography'
import { AccountAction, useAccountActions } from '@/hooks/useAccountActions'
import { paymentProfileQueryOptions } from '@/queries/profile'
import { useOrg } from '@/selectors/useOrg'
import { SupportedPaymentTypes } from '@/types/organization'
import { PaymentType } from '@/types/profile'

export const AccountActions = () => {
  const { orgInfo } = useOrg()
  const { data: paymentProfile } = useSuspenseQuery(paymentProfileQueryOptions)

  const {
    openBranchMobileApp,
    switchToCard,
    switchToWallet,
    disableInstantPayment,
    updateLinkedCard,
    manageYourAccount,
  } = useAccountActions()

  const renderOptions = () => {
    const options: AccountAction[] = [manageYourAccount]

    if (!orgInfo?.supported_payment_types) return options

    const walletSupported = orgInfo.supported_payment_types.includes(
      SupportedPaymentTypes.WALLET
    )

    const directCardSupported = orgInfo.supported_payment_types.includes(
      SupportedPaymentTypes.DIRECT_CARD
    )

    switch (paymentProfile?.active_type) {
      case PaymentType.CARD:
        if (directCardSupported) {
          options.push(updateLinkedCard)
        }
        if (walletSupported) {
          options.push(switchToWallet)
        }
        options.push(disableInstantPayment)
        break
      case PaymentType.WALLET:
        if (walletSupported) {
          options.push(openBranchMobileApp)
        }
        if (directCardSupported) {
          options.push(switchToCard)
        }
        options.push(disableInstantPayment)
        break
      default:
        if (directCardSupported) {
          options.push(switchToCard)
        }
        if (walletSupported) {
          options.push(switchToWallet)
        }
    }
    return options
  }

  return (
    <Box direction="column" align="start">
      <Description size="sm" css={{ mb: '$16' }} color="hint">
        ACCOUNT ACTIONS
      </Description>
      <OptionsMenu options={renderOptions()} />
    </Box>
  )
}
