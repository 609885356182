import { CSSProperties, FC, SVGProps } from 'react'

import { Box } from '@/components/layout'
import { useDynamicSvgImport } from '@/hooks/useDynamicSVGImport'

interface Props {
  iconName: string
  wrapperStyle?: CSSProperties
  svgProp?: SVGProps<SVGSVGElement>
  display?: 'inline' | 'flex'
}

export const SVGIcon: FC<Props> = ({
  iconName,
  wrapperStyle,
  svgProp,
  display = 'inline',
}) => {
  const { loading, SvgIcon } = useDynamicSvgImport(iconName)
  if (loading) return
  return (
    <Box
      as="span"
      css={{
        display: display,
        ...wrapperStyle,
      }}
    >
      {SvgIcon && <SvgIcon {...svgProp} />}
    </Box>
  )
}
