import { FC } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { IconButton } from '@/components/button'
import { Input } from '@/components/input'
import { Loader } from '@/components/loader'
import { Description, Title } from '@/components/typography'
import { useRequestResetPassword } from '@/queries/reset-password'

import { RequestPasswordResetProps } from './RequestPasswordReset'

export interface EmailForm {
  email: string
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email field is required'),
})

export const EnterEmail: FC<RequestPasswordResetProps> = ({
  setStep,
  setEmail,
}) => {
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { email: '' },
  })
  const requestResetPassword = useRequestResetPassword()

  const onFormSubmit = (data: EmailForm) => {
    requestResetPassword.mutate(data, {
      onSuccess: () => {
        setEmail(data.email)
        setStep(1)
      },
    })
  }

  return (
    <div>
      <Title>What's your email address?</Title>
      <Description size="sm" css={{ mt: '$16', mb: '$40' }}>
        To help reset your password, we’ll send you a secure link to access your
        account.
      </Description>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Input {...register('email')} placeholder="Enter Email Address" />
        <IconButton
          css={{ mt: '$24' }}
          type="submit"
          disabled={!isValid || requestResetPassword.isPending}
          iconRight
        >
          {isValid ? 'Continue' : 'Enter details'}
          {requestResetPassword.isPending && <Loader />}
        </IconButton>
      </form>
    </div>
  )
}
