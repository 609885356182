import { FC } from 'react'

import { CodeVerificationForm } from '@/components/verification/CodeVerificationForm'
import { useLogin, useLoginUserMutation, useVerifyCode } from '@/queries/auth'

import { LoginData } from './Login'

interface Props {
  loginData: LoginData
}

export const LoginCodeVerificationForm: FC<Props> = ({ loginData }) => {
  const requestCode = useLogin()
  const verifyCode = useVerifyCode()
  const loginUser = useLoginUserMutation()

  const resendCode = () => {
    requestCode.mutate(loginData)
  }

  const confirmCode = (code: string) => {
    verifyCode.mutate(
      {
        email: loginData.email,
        otp: code,
      },
      {
        onSuccess: () => loginUser.mutate(),
      }
    )
  }

  return (
    <CodeVerificationForm
      onSubmit={confirmCode}
      onResendCode={resendCode}
      phoneLastFourDigits={loginData.phoneLastFourDigits}
      verifyCodePending={verifyCode.isPending}
      requestCodePending={requestCode.isPending}
    />
  )
}
