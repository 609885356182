import { CreateDebitCardInfo, CreateDebitCardResponse } from '@/types/card'

import { http } from './http'

export const createDebitCard = (body: CreateDebitCardInfo) =>
  http.post<CreateDebitCardResponse>(`payout/v2/worker/debit-cards`, body)

export const deleteDebitCard = (entityId: number) =>
  http.delete(`payout/v2/worker/debit-cards/${entityId}`)

export const verifyDebitCard = (body: CreateDebitCardInfo) =>
  http.post('payout/v2/worker/debit-cards/verify', body)
