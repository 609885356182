import { FC, Fragment } from 'react'

import { Description } from '@/components/typography'
import { TermsDetails } from '@/types/registration'

interface Props {
  terms: TermsDetails[]
}

export const TermsLine: FC<Props> = ({ terms }) => (
  <div>
    <Description size="sm" css={{ mt: '$16', mb: '$40' }}>
      Please read and agree to the{' '}
      {terms.map((term, i) => (
        <Fragment key={term.url}>
          <a href={term.url} key={i} target="_blank" rel="noreferrer">
            {term.display_name}
          </a>
          {i !== terms.length - 1 && ', '}
          {i === terms.length - 2 && 'and '}
          {i === terms.length - 1 && '.'}
        </Fragment>
      ))}
    </Description>
  </div>
)
