import qs from 'qs'

import {
  DisbursementDetailsDirect,
  DisbursementDetailsWallet,
  DisbursementRequestParams,
  DisbursementResponse,
} from '@/types/disbursements'

import { http } from './http'

export const getDisbursements = (searchParams: DisbursementRequestParams) => {
  const query = qs.stringify(searchParams)
  return http.get<DisbursementResponse>(
    `payout/v2/worker/disbursements?${query}`
  )
}

export const getDisbursementDetails = (detailsRef: string) =>
  http.get<DisbursementDetailsWallet | DisbursementDetailsDirect>(
    `payout${detailsRef}`
  )
