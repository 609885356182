import { Suspense } from 'react'
import { QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'

import { GenericError } from '@/components/error-communications'
import { PageLoader } from '@/components/loader'
import { PageLayout } from '@/components/page-layout'
import { AuthContext } from '@/context/Auth'
import { useAnalyticsPageView } from '@/hooks/useAnalyticsPageView'
import { globalStyles } from '@/styles/stitches.config'

const RootComponent = () => {
  globalStyles()
  useAnalyticsPageView()
  return (
    <Suspense fallback={<PageLoader />}>
      <PageLayout>
        <Outlet />
        <ReactQueryDevtools
          buttonPosition="bottom-left"
          initialIsOpen={false}
        />
        {process.env.NODE_ENV !== 'production' && (
          <TanStackRouterDevtools
            position="bottom-right"
            initialIsOpen={false}
          />
        )}
      </PageLayout>
    </Suspense>
  )
}
export const Route = createRootRouteWithContext<{
  queryClient: QueryClient
  auth: AuthContext
}>()({
  component: RootComponent,
  errorComponent: GenericError,
})
