import { Flex } from '@/components/layout'
import { styled } from '@/styles/stitches.config'

export const Input = styled('input', {
  display: 'block',
  all: 'unset',
  height: 45,
  padding: '0 $8',
  br: '$md',
  width: '100%',
  border: '2px solid $gray500',
  boxSizing: 'border-box',
  '&:focus': {
    border: '2px solid $secondary500',
  },

  variants: {
    inline: {
      true: {
        width: 'auto',
        display: 'inline-block',
      },
    },
    noBorder: {
      true: {
        border: 'none',
        '&:focus': {
          border: 'none',
        },
      },
    },
  },
})

export const InputWrapper = styled(Flex, {
  br: '$md',
  width: '100%',
  border: '2px solid $gray500',
  boxSizing: 'border-box',
  '&:focus-within': {
    border: '2px solid $secondary500',
  },
})

export const InputIcon = styled('span', {
  mx: '$8',
})

export const PinInputWrapper = styled(Flex, {
  gap: '10px',
})

export const DigitInput = styled('input', {
  backgroundColor: '$gray400',
  width: '50px',
  height: '50px',
  fontSize: '$18',
  fontWeight: '$bold',
  padding: '$8',
  border: '1px solid $gray600',
  borderRadius: '$md',
  textAlign: 'center',
  variants: {
    complete: {
      true: {
        boxShadow: '$1',
        backgroundColor: 'white',
      },
    },
  },
})
