import { FC } from 'react'
import { Box, Button } from '@branch-messenger/willow-ui'
import { useNavigate } from '@tanstack/react-router'

import { FlexGrid } from '@/components/layout'
import { Description, Title } from '@/components/typography'

export const AccountManagement: FC = () => {
  const navigate = useNavigate()

  return (
    <Box $direction="column" $align="start">
      <Title>Manage your account.</Title>
      <Description>
        If you are having any issues, please contact Branch support.
      </Description>
      <Description size="sm" css={{ mb: '$16' }} color="hint">
        ACCOUNT INFO
      </Description>
      <FlexGrid direction="column" align="start" size="lg">
        <Button
          variant="link"
          onClick={() => navigate({ to: '/account/email-update' })}
        >
          Update your email
        </Button>
        {/* TODO: NEED BE Endpoints */}
        {/* <Button
          variant="link"
          onClick={() => navigate({ to: '/account/phone-update' })}
        >
          Update your phone number
        </Button> */}
      </FlexGrid>
    </Box>
  )
}
