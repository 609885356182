import { CreateDebitCardInfo } from '@/types/card'

import { formatAccountNumberLast4 } from './string-formatting'

export const initialCardInfo: CreateDebitCardInfo = {
  token: '',
  owner: {
    first_name: '',
    last_name: '',
    address: {
      address_1: '',
      city: '',
      state: '',
      postal_code: '',
    },
  },
}

export const parseData = (tabapayResponse: string) => {
  const [
    firstName,
    lastName,
    accountNumber,
    ,
    token,
    address1,
    city,
    state,
    postalCode,
  ] = tabapayResponse.split('|')
  return {
    last4: formatAccountNumberLast4(accountNumber),
    createCardRequestInfo: {
      token,
      owner: {
        first_name: firstName,
        last_name: lastName,
        address: {
          address_1: address1,
          city,
          state,
          postal_code: postalCode,
        },
      },
    },
  }
}
