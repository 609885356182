export enum PaymentType {
  CARD = 'CARD',
  WALLET = 'WALLET',
  NONE = 'NONE',
}

export enum BranchDirectCardStatus {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
  NONE = 'NONE',
}

export enum WalletStatus {
  'NOT_CREATED',
  'CREATED',
  'PENDING',
  'REVIEW',
  'UNCLAIMED',
  'ACTIVE',
  'SUSPENDED',
  'FAILED',
  'CLOSED',
}

export interface UpdatePaymentProfileRequest {
  active_type: PaymentType
  from_type?: PaymentType
}

export interface PaymentProfile {
  type: PaymentType
  ref: string
}

export interface PaymentProfileWallet {
  wallet_id: number
  account_number: string
  routing_number: string
  status: WalletStatus
}

export interface PaymentProfileBranchDirectCardAccount {
  id: number
  network: string
  last4: string
  expiration: string
  time_created: string
}

export interface PaymentProfileBranchDirectBankAccount {
  ach_account_id: number
  ach_funding_source_id: number
  account_number_last_four: string
  account_name: string
  time_created: string
  verified: boolean
}
export interface PaymentProfileResponse {
  wallet: PaymentProfileWallet
  branch_direct_card_account: PaymentProfileBranchDirectCardAccount
  branch_direct_bank_account: PaymentProfileBranchDirectBankAccount
  active_type: PaymentType
}
