import { FC, useEffect, useMemo } from 'react'
import { useNavigate } from '@tanstack/react-router'

import { OptionsMenu } from '@/components/options'
import { sendAnalyticsEvent } from '@/lib/mixpanel'
import { useSendDeepLink } from '@/queries/registration'
import { InputType } from '@/types/registration'

interface Props {
  hideDialog: () => void
  resetForm: () => void
  phoneOrEmail: string
  inputType: InputType
}

export const GetHelpContent: FC<Props> = ({
  resetForm,
  hideDialog,
  inputType,
  phoneOrEmail,
}) => {
  const sendDeepLink = useSendDeepLink()
  const navigate = useNavigate()
  useEffect(() => sendAnalyticsEvent('ONBOARDING', 'link-sent:get-help'), [])

  const GetHelpOptions = useMemo(
    () => [
      {
        iconName: 'pencil',
        title: `Change ${phoneOrEmail}`,
        handleClick: () => {
          resetForm()
          hideDialog()
        },
      },
      {
        iconName: 'login',
        title: 'I already have an account',
        handleClick: () => {
          navigate({ to: '/login' })
          hideDialog()
        },
      },
      {
        iconName: 'typing',
        title: 'Send a new link',
        handleClick: () => {
          const data =
            inputType === InputType.PHONE
              ? { phone: phoneOrEmail }
              : { email: phoneOrEmail }
          sendDeepLink.mutate(data)
          hideDialog()
        },
      },
    ],
    [hideDialog, inputType, navigate, phoneOrEmail, resetForm, sendDeepLink]
  )

  return <OptionsMenu options={GetHelpOptions} />
}
