import { FC, PropsWithChildren } from 'react'
import { CSS } from '@stitches/react'

import { SVGIcon } from '../svgIcon'
import { Typography } from '../typography'
import {
  DetailedAlertContainer,
  DetailedAlertContent,
  DetailedAlertHeader,
} from './styles'

interface Props extends PropsWithChildren {
  type: 'warning' | 'success' | 'info' | 'danger'
  title?: string
  css?: CSS
}

const generateAlertIcon = (type: Props['type']) => {
  switch (type) {
    case 'warning':
      return <SVGIcon iconName="warning_triangle" display="flex" />
    case 'success':
      return <SVGIcon iconName="green_info" display="flex" />
    case 'danger':
      return <SVGIcon iconName="error_triangle" display="flex" />
    default:
      return <SVGIcon iconName="blue_info" display="flex" />
  }
}

export const DetailedAlert: FC<Props> = ({ type, title, css, children }) => (
  <DetailedAlertContainer type={type} css={css} hideTitle={!title}>
    <DetailedAlertHeader align="center">
      <div>{generateAlertIcon(type)}</div>
      {title && <Typography bold>{title}</Typography>}
    </DetailedAlertHeader>
    <DetailedAlertContent>{children}</DetailedAlertContent>
  </DetailedAlertContainer>
)
