import { FC } from 'react'

import { SVGIcon } from '@/components/svgIcon'
import { PaymentType } from '@/types/profile'

interface Props {
  paymentType?: PaymentType
}

export const AccountStatusIcon: FC<Props> = ({ paymentType }) => {
  switch (paymentType) {
    case PaymentType.CARD:
      return (
        <SVGIcon
          iconName="debit_card"
          wrapperStyle={{ display: 'flex', width: '$48' }}
        />
      )
    case PaymentType.WALLET:
      return (
        <SVGIcon
          iconName="branch_colored"
          wrapperStyle={{ display: 'flex', width: '$48' }}
        />
      )

    default:
      return (
        <SVGIcon
          iconName="no_payment_selected_circled"
          wrapperStyle={{ display: 'flex', width: '$48' }}
        />
      )
  }
}
