import { FC } from 'react'
import { useNavigate } from '@tanstack/react-router'

import { Button } from '@/components/button'
import { Box, Flex } from '@/components/layout'
import { SVGIcon } from '@/components/svgIcon'
import { Description, Title } from '@/components/typography'

export const DebitCardConnectedConfirmation: FC = () => {
  const navigate = useNavigate()

  return (
    <Box direction="column">
      <Flex justify="center" css={{ mb: '$24' }}>
        <SVGIcon iconName="green_check" />
      </Flex>
      <Box direction="column" align="start">
        <Title>You're all set!</Title>
        <Description>
          Your account is connected and you will start receiving instant
          payouts.
        </Description>
        <Description>
          If you experience any issues, please contact Branch support{' '}
          <a
            target="_blank"
            href="https://support.branchapp.com/hc/en-us/requests/new"
          >
            here
          </a>
          .
        </Description>
      </Box>
      <Box css={{ mt: '$24', mr: 'auto' }}>
        <Button
          onClick={() => {
            navigate({
              to: '/account',
            })
          }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  )
}
