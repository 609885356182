import { PaymentType } from './profile'

export enum DisbursementPaymentType {
  DIRECT = 'Direct',
  WALLET = 'Wallet',
}

export interface DisbursementRequestParams {
  status?: string
  type?: PaymentType
  time_last_attempted_start?: string
  time_last_attempted_end?: string
  time_scheduled_start?: string
  time_scheduled_end?: string
  external_id?: string
  external_store_id?: string
  sort?: string
  direction?: string
  page?: number
  size?: number
}

export type DisbursementMetadata = {
  [key: string]: string | number
}

export interface Disbursement {
  id: number
  employee_id: number
  amount: number
  external_id: number
  type: string
  employee_group: string
  description: string
  display_sub_label: string
  status: string
  status_reason: string
  reason_code: string
  metadata: DisbursementMetadata
  payout: {
    ref: string
    payment_type: DisbursementPaymentType
    amount: number
    fee: number
    time_completed: string
  }
  time_created: string
  time_modified: string
}

export interface DisbursementResponse {
  content: Disbursement[]
  page_number: number
  size: number
  has_next_page: boolean
  has_prev_page: boolean
  next_page: string
  prev_page: string
}

export interface DisbursementDetailsWallet {
  transfer_id: string
  account_number: string
  routing_number: string
  amount: number
  fee: number
  state: string
  time_completed: string
  time_created: string
}

export interface DisbursementDetailsDirect {
  transfer_id: string
  last_four: string
  network: string
  amount: number
  fee: number
  state: string
  time_completed: string
  time_created: string
  time_modified: string
}
