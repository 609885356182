import { FC } from 'react'

import { Box } from '@/components/layout'
import { SVGIcon } from '@/components/svgIcon'
import { Description, Title, Typography } from '@/components/typography'

export const SEONCheck: FC = () => (
  <Box direction="column" align="start">
    <Box align="center" justify="center" css={{ mb: '$24' }}>
      <SVGIcon
        wrapperStyle={{ width: 'auto', marginRight: '24px' }}
        iconName="pending_graphic"
      />
    </Box>
    <Title>We're locating your account.</Title>
    <Description size="sm" css={{ mt: '$16', mb: '$40' }}>
      Please allow us some time while we look up your account information.
    </Description>
    <Typography bold>Do not close your browser.</Typography>
  </Box>
)
