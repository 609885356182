import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'

import { cleanUpCookieandStorage } from '@/lib/token'

export const http = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 3600000,
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' },
})

const interceptRequest = (config: InternalAxiosRequestConfig) => {
  if (import.meta.env.PROD) {
    config.headers['Device-ID'] = localStorage.getItem('DEVICE_ID')
  }
  return config
}

const interceptResponse = (res: AxiosResponse) => res

const reject = (err: AxiosError) => Promise.reject(err)

// We may not want to automatically log out the user if the token is invalid
// when we begin to implement new roles and permissions
const removeAuthCookie = (err: AxiosError) => {
  if (err && err.response && err.response.status === 401) {
    cleanUpCookieandStorage()
  }
  return reject(err)
}

// interceptors
http.interceptors.request.use(interceptRequest, reject)
http.interceptors.response.use(interceptResponse, removeAuthCookie)
