import { useEffect } from 'react'
import { useLocation, useParams } from '@tanstack/react-router'

import { sendAnalyticsEvent } from '@/lib/mixpanel'

export const useAnalyticsPageView = () => {
  const location = useLocation()
  const params = useParams({ strict: false })
  useEffect(() => {
    const pathParams = Object.values(params)

    if (pathParams.length) {
      const locationParts = location.pathname.split('/')
      const strippedPath = locationParts
        .filter(part => !pathParams.includes(part))
        .join('/')
      return sendAnalyticsEvent('PAGEVIEW', strippedPath, params)
    }

    sendAnalyticsEvent('PAGEVIEW', location.pathname)
  }, [location.pathname, params])
}
