import { FC } from 'react'

import { PinInput } from '@/components/input'
import { Description, Span, Title } from '@/components/typography'

interface Props {
  handlePinSubmit: (pin: string[]) => void
  isPending: boolean
}

export const SSNInput: FC<Props> = ({ handlePinSubmit, isPending }) => {
  return (
    <>
      <Title>Verify who you are.</Title>
      <Description css={{ mb: '$24' }}>
        Please enter the last 4 digits of your{' '}
        <Span bold>Social Security Number.</Span>
      </Description>
      <PinInput handlePinSubmit={handlePinSubmit} isPending={isPending} />
    </>
  )
}
