import { LoginRequestBody, LoginResponse, LoginWorkerData } from '@/types/auth'

import { http } from './http'

/**
 * Verifies login credentials and initiate OTP.
 * @param data Contains user credentials
 */
export const login = (data: LoginRequestBody) =>
  http.post<LoginResponse>(`/login`, data)

/**
 * Verifies OTP.
 * @param data Contains user credentials
 */
export const verifyLogin = (data: LoginRequestBody) => http.put(`/login`, data)

/**
 * Logout.
 */
export const logout = () => http.delete(`/login`)

/**
 * Get Worker Info
 * Returns user Orgs and employeeIds.
 */
export const getWorker = () => http.get<LoginWorkerData[]>(`/worker`)

/**
 * Login Worker
 * Returns a token.
 * @param data Contains user credentials
 */
export const loginWorker = (data: LoginWorkerData) => http.post(`/worker`, data)

export const sendEmailVerification = (email: string) =>
  http.post('/verification/email', { email })
