import React, { ChangeEvent, FC, useMemo, useRef, useState } from 'react'

import { IconButton } from '../button'
import { Loader } from '../loader'
import { Typography } from '../typography'
import { DigitInput, PinInputWrapper } from './styles'

interface Props {
  handlePinSubmit: (pin: string[]) => void
  errorMessage?: string
  submitButtonLabel?: string
  incompleteButtonLabel?: string
  numberOfInputs?: number
  isPending?: boolean
}

export const PinInput: FC<Props> = ({
  handlePinSubmit,
  isPending = false,
  errorMessage = '',
  submitButtonLabel = 'Submit',
  incompleteButtonLabel = 'Enter PIN',
  numberOfInputs = 4,
}) => {
  const [pin, setPin] = useState<string[]>(new Array(numberOfInputs).fill(''))
  const inputRefs = useRef<HTMLInputElement[]>([])

  const handlePinChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target
    if (isNaN(Number(value))) return
    const updatedPin = [...pin]
    updatedPin[index] = value
    setPin(updatedPin)

    if (value.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus()
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      const focusedIndex = inputRefs.current.findIndex(
        input => document.activeElement === input
      )

      if (focusedIndex >= 0) {
        const updatedPin = [...pin]
        updatedPin[focusedIndex] = ''

        if (focusedIndex > 0 && pin[focusedIndex] === '') {
          inputRefs.current[focusedIndex - 1].focus()
        }

        setPin(updatedPin)
      }
    }
  }

  const validPin = useMemo(() => !pin.some(digit => digit === ''), [pin])

  return (
    <div>
      <PinInputWrapper>
        {pin.map((digit, index) => (
          <DigitInput
            complete={validPin}
            key={index}
            type="text"
            maxLength={1}
            pattern="\d*"
            inputMode="numeric"
            value={digit}
            onChange={event => handlePinChange(index, event)}
            onKeyDown={handleKeyDown}
            ref={el => (inputRefs.current[index] = el as HTMLInputElement)}
          />
        ))}
      </PinInputWrapper>
      {errorMessage && (
        <Typography color="alert" css={{ mt: '$8' }}>
          {errorMessage}
        </Typography>
      )}
      <IconButton
        onClick={() => handlePinSubmit(pin)}
        disabled={!validPin || isPending}
        iconRight
        css={{ mt: '$24' }}
      >
        {validPin ? submitButtonLabel : incompleteButtonLabel}
        {isPending && <Loader />}
      </IconButton>
    </div>
  )
}
