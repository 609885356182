import { ChangeEvent, FC, useRef, useState } from 'react'
import { Box, Button, Input, Typography } from '@branch-messenger/willow-ui'

import { Loader } from '@/components/loader'
import { Description, Title } from '@/components/typography'
import { useTimer } from '@/hooks/useTimer'

interface Props {
  onSubmit: (code: string) => void
  onResendCode: () => void
  phoneLastFourDigits: string
  requestCodePending: boolean
  verifyCodePending: boolean
}

export const CodeVerificationForm: FC<Props> = ({
  onSubmit,
  onResendCode,
  phoneLastFourDigits,
  requestCodePending,
  verifyCodePending,
}) => {
  const counterRef = useRef<HTMLSpanElement>(null)

  const [code, setCode] = useState('')
  const { isCompleted, resetCountDown } = useTimer(1, 0, counterRef)

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (!value) return setCode('')
    setCode(value)
  }

  const resendCode = () => {
    resetCountDown()
    onResendCode()
  }

  return (
    <Box $direction="column" $align="start">
      <Title>Enter the code we texted you.</Title>
      {phoneLastFourDigits && (
        <Description size="sm" css={{ mt: '$16' }}>
          To verify your identity, please input the code sent to the phone
          number ending in <strong>{phoneLastFourDigits}</strong>.
        </Description>
      )}

      {requestCodePending ? (
        <Loader />
      ) : (
        <Box $direction="column" $align="start" $mt={10}>
          <Input
            name="confirmationCode"
            type="text"
            placeholder="Enter Code"
            onChange={handleCodeChange}
            disabled={verifyCodePending}
          />
          <Typography
            $size="sm"
            $color="textMuted"
            style={{ marginTop: '4px' }}
          >
            Standard messaging rates may apply.
          </Typography>
        </Box>
      )}

      <Box $direction="column" $align="start" $mt={6}>
        <Typography $size="sm" $color="textMuted">
          Problems receiving text?
        </Typography>

        <Typography
          $bold
          $color="textMuted"
          $size="sm"
          style={{
            display: `${isCompleted ? 'none' : 'block'}`,
            marginTop: '4px',
            marginBottom: '8px',
          }}
        >
          Resend Code in <span ref={counterRef}></span>
        </Typography>

        {isCompleted && (
          <Button variant="link" onClick={resendCode}>
            Resend Code {`>`}
          </Button>
        )}
      </Box>

      <Button
        style={{ marginTop: '24px' }}
        onClick={() => onSubmit(code)}
        disabled={!code || requestCodePending || verifyCodePending}
      >
        Enter Code
      </Button>
    </Box>
  )
}
