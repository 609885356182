import { FC } from 'react'
import { Box, Typography } from '@branch-messenger/willow-ui'
import { useNavigate } from '@tanstack/react-router'

import { Button } from '@/components/button'
import { SVGIcon } from '@/components/svgIcon'
import { Description, Title } from '@/components/typography'

interface Props {
  email: string
}

export const AccountUpdateEmailSent: FC<Props> = ({ email }) => {
  const navigate = useNavigate()

  return (
    <Box $direction="column">
      <Box $justify="center" $mb={6}>
        <SVGIcon iconName="email" />
      </Box>
      <Box $direction="column" $align="start">
        <Title>Check your email.</Title>
        {email && (
          <Description>
            To keep your account safe, please verify it's you using the
            verification link sent to: <Typography $bold>{email}</Typography>
          </Description>
        )}
        <Typography $bold style={{ marginTop: '8px' }}>
          Do not share this link with anyone.
        </Typography>
      </Box>
      <Box $mt={6} style={{ marginRight: 'auto' }}>
        <Button
          onClick={() => {
            navigate({
              to: '/account',
            })
          }}
        >
          Go Back to Account
        </Button>
      </Box>
    </Box>
  )
}
