export const spacing = {
  4: '4px',
  6: '6px',
  8: '8px',
  12: '12px',
  14: '14px',
  16: '16px',
  18: '18px',
  20: '20px',
  24: '24px',
  32: '32px',
  40: '40px',
  48: '48px',
}
