import { PasswordValidity } from '@/components/password'

export const isValidPassword = (password: string) => {
  const passwordValidity: PasswordValidity = {
    charLength: false,
    hasLetter: false,
    hasDigit: false,
    cases: false,
    special: false,
  }
  let isValid = false
  let pwStrength = 0

  // At least 8 characters
  if (password.length >= 8) {
    passwordValidity.charLength = true
  }

  if (password.length >= 12) {
    pwStrength += 15
  }

  if (password.length >= 14) {
    pwStrength += 15
  }

  if (password.length >= 16) {
    pwStrength += 10
  }

  // At least 1 letter
  if (/[a-zA-Z]/.test(password)) {
    passwordValidity.hasLetter = true
    pwStrength += 10
  }

  // At least 1 digit
  if (/\d/.test(password)) {
    passwordValidity.hasDigit = true
    pwStrength += 10
  }

  // Include both uppercase and lowercase characters
  if (/[a-z]/.test(password) && /[A-Z]/.test(password)) {
    passwordValidity.cases = true
    pwStrength += 10
  }

  // At least 1 special character
  if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    passwordValidity.special = true
    pwStrength += 10
  }

  if (Object.values(passwordValidity).every(item => item === true)) {
    isValid = true
    const letterMatch = password.match(/[a-zA-Z]/g)
    if (letterMatch && letterMatch.length >= 2) {
      pwStrength += 5
    }

    const digitMatch = password.match(/[a-zA-Z]/g)
    if (digitMatch && digitMatch.length >= 2) {
      pwStrength += 5
    }

    const specialMatch = password.match(/[a-zA-Z]/g)
    if (specialMatch && specialMatch.length >= 2) {
      pwStrength += 5
    }

    const upperCaseMatch = password.match(/[A-Z]/g)
    if (upperCaseMatch && upperCaseMatch.length >= 2) {
      pwStrength += 5
    }
  }

  return { passwordValidity, isValid, pwStrength }
}
