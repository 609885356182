import { createFileRoute } from '@tanstack/react-router'

import { getTrackID } from '@/api/registration'
import { VerifyCodeError } from '@/components/error-communications'
import { EnterCode } from '@/containers/EnterCode'

type Search = {
  token: string
}

const RouteComponent = () => {
  const { data } = Route.useLoaderData()
  return <EnterCode {...data} />
}

export const Route = createFileRoute(
  `/registration/organizations/$orgId/exchange`
)({
  validateSearch: (search: Record<string, unknown>): Search => {
    return {
      token: search.token as string,
    }
  },
  loaderDeps: ({ search }) => {
    return {
      token: search.token,
    }
  },
  beforeLoad: ({ context, params }) => context.auth.setOrgId(params.orgId),
  loader: ({ deps: { token }, params }) => getTrackID(params.orgId, token),
  component: RouteComponent,
  errorComponent: VerifyCodeError,
})
