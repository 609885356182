import { createContext, ReactNode, useContext, useState } from 'react'

export interface AuthContext {
  orgId: string
  setOrgId: (value: string) => void
}

const AuthContext = createContext<AuthContext | null>(null)

export function AuthProvider({ children }: { children: ReactNode }) {
  const [orgId, setOrgId] = useState<string>('')

  return (
    <AuthContext.Provider
      value={{
        orgId,
        setOrgId,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
