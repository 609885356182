import { FC, ReactNode } from 'react'

import { Button } from '@/components/button'
import { Box, Flex } from '@/components/layout'
import { SVGIcon } from '@/components/svgIcon'
import { Description, Title, Typography } from '@/components/typography'
import { usePayoutFee } from '@/hooks/usePayoutFee'
import { useOrg } from '@/selectors/useOrg'

interface Props {
  setStep: (step: number) => void
}

interface InfoBoxProps {
  iconName: string
  children: ReactNode
}

const InfoBox: FC<InfoBoxProps> = ({ iconName, children }) => (
  <Box>
    <SVGIcon
      iconName={iconName}
      wrapperStyle={{
        width: '$24',
        marginRight: '$16',
        display: 'flex',
      }}
    />
    <Typography bold>{children}</Typography>
  </Box>
)

export const DebitCardInformational: FC<Props> = ({ setStep }) => {
  const { orgName } = useOrg()
  const { formattedFee } = usePayoutFee()
  const proceed = () => setStep(1)

  return (
    <Box direction="column">
      <Flex justify="center" css={{ mb: '$24' }}>
        <SVGIcon iconName="card_stack" />
      </Flex>
      <Box direction="column" align="start">
        <Title>Let’s get your debit card connected.</Title>
        <Description>
          Once your debit card is connected, your {orgName} payments will start
          flowing.
        </Description>
        <Flex direction="column" align="start" css={{ mt: '$24', gap: '$32' }}>
          <InfoBox iconName="connect_card">
            Connecting your card to Branch Direct triggers payments to that
            card.
          </InfoBox>
          <InfoBox iconName="debit_card">
            Card must be an active debit card.
          </InfoBox>
          {!!formattedFee && (
            <InfoBox iconName="cash_roll">
              The convenience fee incurred on each payment is {formattedFee}
            </InfoBox>
          )}
        </Flex>
      </Box>
      <Box css={{ mt: '$24', mr: 'auto' }}>
        <Button onClick={proceed}>Continue</Button>
      </Box>
    </Box>
  )
}
