import { createFileRoute } from '@tanstack/react-router'

import { Landing } from '@/containers/Landing'

export const Route = createFileRoute('/')({
  component: Landing,
  validateSearch: (search: Record<string, unknown>): { org_id: string } => {
    return {
      org_id: search.org_id as string,
    }
  },
})
