import { ErrorBoundary } from 'react-error-boundary'

import { AccountActions } from './AccountActions'
import { AccountLoadingError } from './AccountLoadingError'
import { AccountStatus } from './AccountStatus'

export const AccountProfile = () => (
  <ErrorBoundary fallback={<AccountLoadingError />}>
    <AccountStatus />
    <AccountActions />
  </ErrorBoundary>
)
