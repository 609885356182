import { FC } from 'react'

import { Box } from '../layout'
import { SVGIcon } from '../svgIcon'
import { Description, Title } from '../typography'

export interface OptionItemType {
  iconName: string
  title: string
  description?: string
  handleClick?: () => void
  visible?: boolean
}

type Props = OptionItemType

export const OptionItem: FC<Props> = ({
  iconName,
  title,
  description,
  handleClick,
  visible = true,
}) => {
  if (!visible) return null

  return (
    <Box
      justify="between"
      css={{ mb: '$16', cursor: 'pointer' }}
      onClick={handleClick}
    >
      <SVGIcon
        wrapperStyle={{ width: '$24', marginRight: '$16', display: 'flex' }}
        iconName={iconName}
      />
      <Box direction="column" align="start">
        <Title size="sm">{title}</Title>
        {description && (
          <Description css={{ mt: '$4' }} size="xs">
            {description}
          </Description>
        )}
      </Box>
      <SVGIcon wrapperStyle={{ width: '$24' }} iconName="arrow_right" />
    </Box>
  )
}
