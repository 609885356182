import { styled } from '@/styles/stitches.config'

import { Box, Flex } from '../layout'

export const Alert = styled('div', {
  padding: '$8 $16',

  variants: {
    type: {
      info: {
        backgroundColor: '$secondary300',
        borderLeft: '$sizes$4 solid $secondary500',
      },
      success: {
        backgroundColor: '$success300',
        borderLeft: '$sizes$4 solid $success500',
      },
      warning: {
        backgroundColor: '$warn300',
        borderLeft: '$sizes$4 solid $warn500',
      },
      danger: {
        backgroundColor: '$danger300',
        borderLeft: '$sizes$4 solid $danger500',
      },
      secondary: {
        backgroundColor: '$gray400',
      },
    },
    size: {
      sm: {
        fontSize: '$12',
        padding: '$8 $12',
      },
    },
  },

  defaultVariants: {
    type: 'info',
  },
})

export const DetailedAlertContainer = styled(Alert, {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'flexStart',
  flexShrink: 0,
  variants: {
    hideTitle: {
      true: {
        flexDirection: 'row',
      },
    },
  },
})

export const DetailedAlertHeader = styled(Flex, {
  alignSelf: 'stretch',
  gap: '$8',
})

export const DetailedAlertContent = styled(Box, {
  flex: '1 0 0',
  paddingLeft: '$24',
  boxSizing: 'border-box',
})
