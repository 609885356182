import { GetTrackIDResponse } from '@/types/registration'
import {
  RequestResetPasswordRequestBody,
  ResetPasswordRequestBody,
  SendResetPasswordOTPRequestBody,
} from '@/types/reset-password'

import { http } from './http'

/**
 * This end-point is used request a password reset
 * link to reset password with a deep-link sent to user's email
 * @param data contains user's email
 */
export const requestResetPassword = (data: RequestResetPasswordRequestBody) =>
  http.post(`registration/password`, data)

/**
 * This end-point is used to exchange a temporary token with a permanent track id for resetting password.
 * The temporary token gets invalidated after retrieving the track id.
 * @param token exhange token to be use to get track id
 */
export const getResetPasswordTrackID = (token: string) =>
  http.get<GetTrackIDResponse>(`registration/password/exchange?token=${token}`)

/**
 * This end-point is used to send a otp to user's phone number for resetting password.
 * @param data contains track_id
 */
export const sendResetPasswordOTP = (data: SendResetPasswordOTPRequestBody) =>
  http.post(`registration/password/otp`, data)

/**
 * This end-point is used to reset a user's password with a valid otp code.
 * @param data contains track_id, password, confirm_password, otp
 */
export const resetPassword = (data: ResetPasswordRequestBody) =>
  http.put(`registration/password`, data)
