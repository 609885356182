import { ChangeEvent, FC } from 'react'
import { Box, Button, Input } from '@branch-messenger/willow-ui'

import { Title } from '@/components/typography'

interface Props {
  email: string
  isPending: boolean
  setEmail: (email: string) => void
  onSubmit: () => void
}

export const EmailUpdateForm: FC<Props> = ({
  email,
  isPending,
  onSubmit,
  setEmail,
}) => {
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (!value) return setEmail('')
    setEmail(value)
  }

  return (
    <Box $direction="column" $align="start">
      <Title>What's your updated email address?</Title>
      <Input
        name="Email"
        type="email"
        placeholder="Enter new email"
        onChange={handleInputChange}
        style={{ margin: '16px 0' }}
      />
      <Box $direction="column" $align="start">
        <Button
          style={{ margin: '$16 0 $40 0', marginRight: 'auto' }}
          disabled={!email || isPending}
          onClick={() => onSubmit()}
        >
          {!email ? 'Enter Info' : 'Continue'}
        </Button>
      </Box>
    </Box>
  )
}
