import { FC, useContext } from 'react'

import { Button } from '@/components/button'
import { Box } from '@/components/layout'
import { SVGIcon } from '@/components/svgIcon'
import { Description, Title } from '@/components/typography'
import { DialogContext } from '@/context'

import { ContactSupportContent } from './ContactSupportContent'

export const IdentitySupport: FC = () => {
  const { showDialog } = useContext(DialogContext)

  const openContactSupportDialog = () => {
    showDialog({
      title: 'Contact support.',
      description: 'How would you like to be helped?',
      children: <ContactSupportContent />,
    })
  }

  return (
    <Box direction="column" align="start">
      <Box align="center" justify="center" css={{ mb: '$24' }}>
        <SVGIcon
          wrapperStyle={{ width: 'auto', marginRight: '24px' }}
          iconName="support"
        />
      </Box>
      <Title>Contact support for identity verification help.</Title>
      <Description css={{ mb: '$24' }}>
        We were unable to verify your identity. Please contact support.
      </Description>
      <Button onClick={openContactSupportDialog} css={{ mt: '$40' }}>
        Get Help
      </Button>
    </Box>
  )
}
