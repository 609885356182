import { Input } from '@/components/input'
import { Box, Flex } from '@/components/layout'
import { Typography } from '@/components/typography'
import { styled } from '@/styles/stitches.config'

export const PhoneInputContainer = styled(Box, {
  border: '2px solid $gray500',
  borderRadius: '$md',
})
export const CountryCodeTrigger = styled('span', {
  display: 'flex',
  border: 'none',
  borderRight: '2px solid $gray500',
  width: 75,
  '&:hover': { backgroundColor: 'white', cursor: 'pointer' },
  justifyContent: 'center',

  fontSize: '$24',
})

export const CountryCodeDropdownContent = styled(Flex, {
  width: 400,
  height: 400,
})

export const CountrySearchInput = styled(Input, {
  width: '90%',
  mb: '$12',
  mt: '$12',
})

export const PhoneText = styled(Typography, {
  width: 75,
  ml: '$24',
})

export const PhoneInput = styled(Input, {
  border: 'none !important',
  '&:focus': { border: 'none', borderLeft: 'none' },
})

export const CountryCodeText = styled(Typography, {
  ml: '$12',
  mr: '$4',
})
