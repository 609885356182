import { FC } from 'react'

import { Box } from '../layout'
import { OptionItem, OptionItemType } from './OptionItem'

interface Props {
  options: OptionItemType[]
}

export const OptionsMenu: FC<Props> = ({ options }) => (
  <Box direction="column" justify="start" css={{ width: '95%' }}>
    {options.map(option => (
      <OptionItem key={option.title} {...option} />
    ))}
  </Box>
)
