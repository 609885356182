import { FC, useState } from 'react'

import { AccountUpdateCodeVerification } from './AccountUpdateCodeVerification'
import { AccountUpdateEmailSent } from './AccountUpdateEmailSent'
import { PhoneUpdateForm } from './PhoneUpdateForm'

export const PhoneUpdate: FC = () => {
  const [step, setStep] = useState(0)
  const [phone, setPhone] = useState('')

  const handlePhoneUpdateSubmit = () => {
    // update phone
    setStep(1)
  }

  const resendCode = () => {
    // resend code
  }

  const confirmCode = () => {
    // confirm code
    setStep(2)
  }

  switch (step) {
    case 0:
      return (
        <PhoneUpdateForm
          isPending={false}
          phone={phone}
          setPhone={setPhone}
          onSubmit={handlePhoneUpdateSubmit}
        />
      )
    case 1:
      return (
        <AccountUpdateCodeVerification
          resendCode={resendCode}
          confirmCode={confirmCode}
          verifyCodePending={false}
          requestCodePending={false}
        />
      )
    case 2:
      return <AccountUpdateEmailSent email={''} />
    default:
      return (
        <PhoneUpdateForm
          isPending={false}
          phone={phone}
          setPhone={setPhone}
          onSubmit={handlePhoneUpdateSubmit}
        />
      )
  }
}
