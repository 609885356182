import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { AsYouType, CountryCode } from 'libphonenumber-js'

import { Dropdown, DropdownMenuItem } from '@/components/dropdown'
import { Box } from '@/components/layout'
import { ScrollArea } from '@/components/scroll-area'
import { Typography } from '@/components/typography'
import { COUNTRIES } from '@/lib/countries'
import { Country } from '@/types/countries'

import {
  CountryCodeDropdownContent,
  CountryCodeText,
  CountryCodeTrigger,
  CountrySearchInput,
  PhoneInput,
  PhoneInputContainer,
  PhoneText,
} from './styles'

interface Props {
  onChange: (value: string) => void
  initialPhoneNumber?: string
  setCountryCode?: (value: CountryCode) => void
}

export const PhoneNumberInput: FC<Props> = ({
  onChange,
  initialPhoneNumber = '',
  setCountryCode,
}) => {
  const [country, setCountry] = useState<Country>(COUNTRIES[0])
  const [phoneNumber, setPhoneNumber] = useState<string>(initialPhoneNumber)
  const [searchCriteria, setSearchCriteria] = useState<string>('')

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputNumber = event.target.value
    const asYouTypeFormatter = new AsYouType(country.country)
    let formattedNumber = asYouTypeFormatter.input(inputNumber)
    // Handle backspace
    if (inputNumber.length < phoneNumber.length) {
      const lastChar = formattedNumber.slice(-1)
      if (lastChar === ')' || lastChar === '(') {
        formattedNumber = formattedNumber.slice(0, -2)
      }
    }
    setPhoneNumber(formattedNumber)
    onChange(`+${country.phone}${formattedNumber}`)
  }
  const handleCountryChange = (country: Country) => {
    setPhoneNumber(initialPhoneNumber)
    setCountry(country)
    onChange(``)
  }

  const filteredOptions = useMemo(
    () =>
      COUNTRIES.filter(
        item =>
          !country ||
          item.name.toLowerCase().includes(searchCriteria.toLowerCase())
      ),
    [country, searchCriteria]
  )

  useEffect(() => {
    if (setCountryCode) {
      setCountryCode(country.country)
    }
  }, [country.country, setCountryCode])

  return (
    <PhoneInputContainer>
      <Dropdown
        arrow
        trigger={<CountryCodeTrigger>{country.emoji}</CountryCodeTrigger>}
        content={
          <CountryCodeDropdownContent direction="column">
            <CountrySearchInput
              placeholder="Search Country"
              value={searchCriteria}
              onChange={e => setSearchCriteria(e.target.value)}
            />
            <ScrollArea>
              {filteredOptions.map(country => (
                <DropdownMenuItem
                  onClick={() => handleCountryChange(country)}
                  key={country.name}
                >
                  <Box>
                    <Typography>{country.emoji}</Typography>
                    <PhoneText bold>+{country.phone}</PhoneText>
                    <Typography>{country.name}</Typography>
                  </Box>
                </DropdownMenuItem>
              ))}
            </ScrollArea>
          </CountryCodeDropdownContent>
        }
      />
      <CountryCodeText>+{country.phone}</CountryCodeText>
      <PhoneInput
        type="tel"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={true}
        maxLength={15}
      />
    </PhoneInputContainer>
  )
}
