import { styled } from '@/styles/stitches.config'

import { Flex } from '../layout'

const StandardButtonStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minWidth: '120px',
  alignItems: 'center',
  flexShrink: 0,
  borderRadius: '$md',
  backgroundColor: 'white',
  color: 'black',
  transition: 'background-color 0.3s ease',
  cursor: 'pointer',
  padding: '$8',
  fontSize: '$md',
}

export const Button = styled('button', {
  ...StandardButtonStyles,
  boxShadow: '$1',
  '&:disabled': {
    backgroundColor: '$gray500',
    opacity: 0.75,
    border: '2px solid $gray500',
    cursor: 'not-allowed',
  },
  '&:hover:not([disabled])': {
    filter: 'brightness(90%)',
  },

  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
    },
    display: {
      block: {
        display: 'block',
      },
    },
    color: {
      primary: {
        color: '$primary500',
      },
      secondary: {
        color: '$secondary500',
      },
      black: {
        color: 'black',
      },
      danger: {
        color: '$danger500',
      },
    },

    mode: {
      filled: {
        backgroundColor: '$primary500',
        border: '2px solid $primary500',
        color: 'black',
      },
      outline: {
        backgroundColor: 'white',
        border: '2px solid $primary500',
        boxShadow: 'none',
        color: 'black',
      },
      ghost: {
        backgroundColor: 'transparent',
        border: 'none',
        color: '$secondary500',
        boxShadow: 'none',
      },
      link: {
        backgroundColor: 'transparent',
        border: 'none',
        color: '$secondary500',
        boxShadow: 'none',
        alignItems: 'start',
        pl: '0',

        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  compoundVariants: [
    {
      color: 'secondary',
      mode: 'outline',
      css: {
        border: '2px solid $secondary500',
        color: 'black',
      },
    },
    {
      color: 'secondary',
      mode: 'filled',
      css: {
        backgroundColor: '$secondary500',
        border: '2px solid $secondary500',
        color: 'white',
      },
    },
    {
      color: 'black',
      mode: 'outline',
      css: {
        backgroundColor: 'white',
        border: '2px solid black',
      },
    },
    {
      mode: 'filled',
      color: 'black',
      css: {
        backgroundColor: 'black',
        color: 'white',
        border: '2px solid black',
      },
    },
    {
      color: 'danger',
      mode: 'outline',
      css: {
        color: 'black',
        borderColor: '$danger500',
      },
    },
    {
      color: 'danger',
      mode: 'filled',
      css: {
        color: 'white',
        backgroundColor: '$danger500',
        border: '2px solid $danger500',
      },
    },
  ],

  defaultVariants: {
    mode: 'filled',
    color: 'primary',
  },
})

export const IconButton = styled(Button, {
  '-webkit-appearance': 'none',
  display: 'flex',
  flexDirection: 'row',

  variants: {
    iconLeft: {
      true: {
        '& svg': {
          marginRight: '$4',
        },
      },
    },
    iconRight: {
      true: {
        '& svg': {
          marginLeft: '$4',
        },
      },
    },
  },
  defaultVariants: {
    iconLeft: 'true',
  },
})

const detailedButtonActiveStyle = {
  backgroundColor: '$secondary500',
  color: 'white',
  filter: 'brightness(100%)',
}

export const DetailButtonContainer = styled('button', {
  ...StandardButtonStyles,
  flexShrink: 0,
  flexDirection: 'row',
  border: '2px solid $gray500',
  br: '$lg',

  '&:hover:not([disabled])': detailedButtonActiveStyle,

  '&:disabled': {
    filter: 'brightness(95%)',
    cursor: 'not-allowed',
  },

  variants: {
    active: {
      true: detailedButtonActiveStyle,
    },
  },
})

export const DetailButtonIconContainer = styled('span', {
  padding: '$8',
  br: '$xl',
  backgroundColor: 'white',

  defaultVariants: {
    align: 'center',
    justify: 'center',
  },
})

export const DetailButtonContentContainer = styled(Flex, {
  width: '100%',
  padding: '$16',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '8px',

  '@tablet': {
    width: '100%',
    textAlign: 'start',
  },
})

export const DetailButtonText = styled(Flex, {
  flexShrink: 0,
  alignSelf: 'stretch',
})
