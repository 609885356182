import { queryOptions } from '@tanstack/react-query'

import { getPayoutFees } from '@/api/payout'

const GET_PAYOUT_FEE_QUERY = 'get-payout-fee-query'

export const payoutFeesQueryOptions = (orgId: string) => {
  return queryOptions({
    queryKey: [GET_PAYOUT_FEE_QUERY, orgId],
    queryFn: async () => {
      if (!orgId) return
      const { data } = await getPayoutFees(orgId)
      return data
    },
  })
}
