import {
  PaymentProfileResponse,
  UpdatePaymentProfileRequest,
} from '@/types/profile'

import { http } from './http'

export const getPaymentProfile = () =>
  http.get<PaymentProfileResponse>(`payout/v2/worker/payment-profile`)

export const updatePaymentProfile = (data: UpdatePaymentProfileRequest) =>
  http.put<PaymentProfileResponse>(`payout/v2/worker/payment-profile`, data)
