import { Box } from '@/components/layout'
import { SVGIcon } from '@/components/svgIcon'
import { Description, Title } from '@/components/typography'

export const NoPayouts = () => (
  <Box direction="column" align="start">
    <Title size="xl" css={{ my: '$16' }}>
      Payouts
    </Title>
    <Box justify="between" css={{ mb: '$16', cursor: 'pointer' }}>
      <SVGIcon
        wrapperStyle={{ width: '$28', marginRight: '$16', display: 'flex' }}
        iconName={'clock'}
      />
      <Box direction="column" align="start">
        <Description css={{ mt: '$4' }}>No payouts yet</Description>
      </Box>
    </Box>
  </Box>
)
