import { FC } from 'react'

import { Checkbox } from '@/components/checkbox'

import { UL } from './styles'
import { PasswordValidity } from './types'

interface Props {
  passwordValidity: PasswordValidity
}

export const PasswordRequirements: FC<Props> = ({ passwordValidity }) => (
  <UL>
    <li data-testid="character-length">
      <Checkbox
        id="characterLength"
        mode="circular"
        checked={passwordValidity.charLength}
        disabled
        label="At least 8 characters"
      />
    </li>
    <li data-testid="letter-requirement">
      <Checkbox
        id="letterRequirement"
        mode="circular"
        disabled
        checked={passwordValidity.hasLetter}
        label="At least 1 letter (a, b, c...)"
      />
    </li>
    <li data-testid="digit-requirement">
      <Checkbox
        id="digit-requirement"
        mode="circular"
        disabled
        checked={passwordValidity.hasDigit}
        label="At least 1 digit (1, 2, 3...)"
      />
    </li>
    <li data-testid="case-requirement">
      <Checkbox
        id="case-requirement"
        mode="circular"
        disabled
        checked={passwordValidity.cases}
        label="Include both uppercase and lowercase characters"
      />
    </li>
    <li data-testid="special-character-requirement">
      <Checkbox
        id="special-character-requirement"
        mode="circular"
        disabled
        checked={passwordValidity.special}
        label="At least 1 special character (!, @, #...)"
      />
    </li>
  </UL>
)
