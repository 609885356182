import { FC, ReactElement } from 'react'

import {
  DropdownArrow,
  DropdownContentContainer,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownPortal,
} from './styles'

interface Props {
  trigger: ReactElement
  content: ReactElement
  arrow?: boolean
}

export const Dropdown: FC<Props> = ({ trigger, content, arrow = false }) => (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>{trigger}</DropdownMenuTrigger>
    <DropdownPortal>
      <DropdownContentContainer side="bottom">
        {content}
        {arrow && <DropdownArrow />}
      </DropdownContentContainer>
    </DropdownPortal>
  </DropdownMenu>
)
