import { RouterProvider } from '@tanstack/react-router'

import { useAuth } from './context/Auth'
import { queryClient } from './lib/query-client'
import { router } from './lib/router'

export const App = () => {
  const auth = useAuth()
  return <RouterProvider router={router} context={{ auth, queryClient }} />
}
