import { useSuspenseQuery } from '@tanstack/react-query'

import { useAuth } from '@/context/Auth'
import { orgInfoQueryOptions } from '@/queries/organization'

export const useOrg = () => {
  const { orgId } = useAuth()

  const { data } = useSuspenseQuery(orgInfoQueryOptions(orgId))

  return { ...data }
}
