import { FC, PropsWithChildren } from 'react'

import defaultPartnerGraphic from '@/assets/images/fallback_partner_graphic.png'
import { SVGIcon } from '@/components/svgIcon'
import { useLogout } from '@/queries/auth'
import { useOrg } from '@/selectors/useOrg'

import { Button } from '../button'
import { Box, Flex } from '../layout'
import { Typography } from '../typography'
import {
  OrgLogoContainer,
  PageLayoutContainer,
  PageLayoutContent,
  PageLayoutContentChildren,
  PageLayoutFooter,
  PageLayoutHeader,
  PageLayoutItem,
  PartnerLogoContainer,
} from './styles'

export const PageLayout: FC<PropsWithChildren> = ({ children }) => {
  const logout = useLogout()
  const { orgInfo } = useOrg()
  const handleLogout = () => logout.mutate()
  const isAccountRoute = window.location.pathname.includes('/account')

  return (
    <PageLayoutContainer direction="column">
      <PageLayoutHeader align="center">
        <OrgLogoContainer>
          {orgInfo?.logo_url ? (
            <img src={orgInfo.logo_url} alt="org_logo" />
          ) : (
            <SVGIcon iconName="branch_full" />
          )}
        </OrgLogoContainer>

        <Flex css={{ ml: 'auto' }}>
          <a
            href="https://support.branchapp.com/hc/en-us/categories/20572217231899-Branch-Direct"
            target="_blank"
          >
            <SVGIcon
              iconName="circle_question_mark"
              wrapperStyle={{ display: 'flex', cursor: 'pointer' }}
            />
          </a>
          {isAccountRoute && (
            <Button
              onClick={handleLogout}
              mode="ghost"
              css={{ fontWeight: '$bold' }}
            >
              Log Out
            </Button>
          )}
        </Flex>
      </PageLayoutHeader>

      <PageLayoutContent css={{ width: '100%' }}>
        <PageLayoutItem direction="column">
          <PageLayoutContentChildren direction="column" justify="center">
            {children}
          </PageLayoutContentChildren>
        </PageLayoutItem>
        <PageLayoutItem
          hideOnSmallerScreen
          justify="center"
          css={{ padding: '0 2.5% 0 5%' }}
        >
          <PartnerLogoContainer>
            <img
              src={defaultPartnerGraphic}
              alt="partner_graphic"
              height="90%"
            />
          </PartnerLogoContainer>
        </PageLayoutItem>
      </PageLayoutContent>

      <PageLayoutFooter align="center">
        <Box
          align="start"
          direction="column"
          justify="center"
          css={{ width: 'auto' }}
        >
          <Typography size="xs" color="muted">
            All rights reserved.
          </Typography>
          <Typography size="xs" color="muted">
            Copyright © {new Date().getFullYear()} Branch.
          </Typography>
        </Box>
        <Box css={{ ml: 'auto', width: 'auto' }}>
          <Typography size="xs" color="muted">
            Powered by
          </Typography>
          <div>
            <SVGIcon
              iconName="branch_full"
              wrapperStyle={{ marginLeft: '12px', display: 'flex' }}
              svgProp={{ transform: 'scale(1.1)' }}
            />
          </div>
        </Box>
      </PageLayoutFooter>
    </PageLayoutContainer>
  )
}
